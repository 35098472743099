<template>
    <div class="global-modal-cover" 
     v-if="this.$store.getters['modal/getModalActive']"
     v-on:click="onclick()">
      <div class="gray-background-full">
      </div>
      <div class="d-flex justify-content-center modal align-items-center">
        <div class="status-bubble p-2">
          
          <div>

            <div
             v-for="(item, index) in trimNullContentItem(this.$store.getters['modal/getModalMessage'])" :key="index"
             class="text-pre-wrap"
             :class="{
               'mt-1': item.mt === 1, 'mt-2': item.mt === 2, 'mt-3': item.mt === 3, 'mt-4': item.mt === 4,
               'mb-1': item.mb === 1, 'mb-2': item.mb === 2, 'mb-3': item.mb === 3, 'mb-4': item.mb === 4,
             }"
            >

              <span 
               v-if="item.type==='text'"
               :class="{
                 'text-fw-bold': item.fontWeight === 'bold',
                 'text-fw-underline': item.fontWeight === 'underline',
                 'text-fw-normal': item.fontWeight === 'normal',
                 'text-color-maincolor': item.color === 'maincolor',
                 'text-color-notice': item.color === 'notice',
               }"
              >{{ item.content }}
              </span>

              <div
               v-if="item.type==='icon'"
              >
                <div v-if="item.content==='loading'">
                  <div class="spinner-border text-color-maincolor" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                <div v-if="item.content==='success'">
                  <div 
                   class="mask-image-style img-success mx-auto" 
                   :style="maskImageStyleObject(require('@/assets/common/icons/icon_success.svg'))"
                  ></div>
                </div>
                <div v-if="item.content==='fail'">
                  <img src='@/assets/common/icons/icon_error.svg' style="height:30%; width:30%;">
                </div>
                <div v-if="item.content==='edit'">
                  <div class="mask-image-style img-edit mx-auto"
                   :style="maskImageStyleObject(require('@/assets/common/icons/icon_edit.svg'))"
                  ></div>
                </div>

              </div>

              <span 
               v-if="item.type==='text-array'"
              >
                <p 
                 v-for="(contentItem, contentIndex) in item.content" :key="contentIndex"
                 :class="{
                    'fw-bold': contentItem.bold,
                    'text-color-maincolor': contentItem.color === 'maincolor',
                    'text-color-notice': contentItem.color === 'notice',
                 }"
                 class="d-inline"
                >
                <ins class="mx-1" v-if="contentItem.underline">{{ contentItem.text }}</ins>
                <span v-else>{{ contentItem.text }}</span>
                </p>
              </span>

            </div>

            <div 
             v-if="this.$store.getters['modal/getModalStatus']==='select'"
             class="my-3 mx-3">
              <div>
                <button class="btn btn-next w-100 my-1" v-on:click="clickCancel()">
                  {{ this.$store.getters['modal/getModalOptions'][0] !== undefined ? this.$store.getters['modal/getModalOptions'][0] : "取消" }}
                </button>
              </div>
              <div>
                <button class="btn btn-sub w-100 my-1" v-on:click="clickConfirm()">
                  {{ this.$store.getters['modal/getModalOptions'][1] !== undefined ? this.$store.getters['modal/getModalOptions'][1] : "確定" }}
                </button>
              </div>
            </div>

            <div 
             v-if="this.$store.getters['modal/getModalStatus']==='confirm'"
             class="my-3 d-flex justify-content-center mx-3">
              <button class="btn btn-next w-100 my-1" v-on:click="clickCancel()">
                {{ this.$store.getters['modal/getModalOptions'][0] !== undefined ? this.$store.getters['modal/getModalOptions'][0] : "確定" }}
              </button>
            </div>

          </div>

        </div>
      </div>
      
    </div>
</template>
<script>
export default {
    methods: {
        onclick() {
            if ( this.$store.getters['modal/getModalStatus']==='fail' )
                this.$store.commit('modal/turnModalActive');
        },
        clickCancel() {
          this.$store.commit('modal/restoreConfirmState');
          this.$store.commit('modal/turnModalActive');
        },
        clickConfirm() {
          this.$store.commit('modal/confirm');
        },
        trimNullContentItem( items ) {
          let array = [];
          for ( let i = 0 ; i < items.length ; i++ ) {
            if ( items[i].content !== '' ) {
              array.push(items[i]);
            }
          }
          return array;
        }
    },
}
</script>
<style scope>
@import './style/modal.css';
</style>