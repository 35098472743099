<template>
  <div>
    <Header />
    <div v-if="pageStatus.step===1">
      <SearchOrder
       @search-result="catchOrderData"
       @find-ticket="pageStatus.step=3"
      />
    </div>
    <div v-if="pageStatus.step===2">
      <FillInPassengerInfo 
       v-bind:_searchResult="pageStatus.searchResult"
       v-bind:info="pageStatus.info"
       @back="pageStatus.step=1"
      />
    </div>
    <div v-if="pageStatus.step===3">
      <TakeTicketBack
       @search-result="catchOrderData"
       @download-ticket="pageStatus.step=1"
      />
    </div>
    

  </div>
</template>
<script>
import Header from '@/components/layout-components/header/header.vue'
import SearchOrder from "@/components/pages/downloadPage/searchOrder.vue";
import FillInPassengerInfo from "@/components/pages/downloadPage/fillInPassengerInfo.vue";
import TakeTicketBack from "@/components/pages/downloadPage/takeTicketBack.vue";
export default {
  components: {
    Header,
    SearchOrder,
    FillInPassengerInfo,
    TakeTicketBack,
  },
  data() {
    return {
      pageStatus: {
        step: 1,
        searchResult: {},
        info: {}
      },
      
    };
  },
  methods: {
    init() {
      this.$store.commit("button/changeButtonActive", {
        id: 3,
      });

    },
    catchOrderData( data ) {
      this.pageStatus.searchResult = data.result;
      this.pageStatus.info = data.info;
      this.pageStatus.step = 2;
    }

  
  },
  mounted() {
    this.init();
  },
};
</script>
