
const state = () => ({
    buttons: [
      { id: 0, 
        name: '我的票卷',
        imgurl: require('@/assets/common/icons/icon_my_tickets_gray.svg'), 
        route: '/my-tickets',
        active: false,
        block: false, // 不給按 => true ;
      },
      { id: 1, 
        name: '線上訂票',
        imgurl: require('@/assets/common/icons/icon_booking_ticket_gray.svg'), 
        route: '/booking',
        active: false,
        block: false,
      },
      { id: 2, 
        name: '查詢訂單',
        imgurl: require('@/assets/common/icons/icon_search_order_gray.svg'), 
        route: '/order',
        active: false,
        block: false,
      },
      { id: 3, 
        name: '行動取票',
        imgurl: require('@/assets/common/icons/icon_download_ticket_gray.svg'), 
        route: '/d',
        active: false,
        block: false,
      },
      { id: 4, 
        name: '即時客服',
        imgurl: require('@/assets/common/icons/icon_online_contact_gray.svg'), 
        route: '',
        active: false,
        block: true,
      },
    ],

});

const getters = {

    getButtonInfoById: (state) => (id) => {
      return state.buttons.find(buttons => buttons.id === id);
      // same state.buttons[index] ;
    },
    getButtonInfoByName: (state) => (name) => {
      return state.buttons.find(buttons => buttons.name === name);
    },

};

const actions = {};

const mutations = {
    changeButtonActive( state, id ){
      for( let i = 0 ; i < state.buttons.length ; i++ ) {
        if ( i === id.id ) {
          state.buttons[i].active = true;
        }
        else 
          state.buttons[i].active = false;
      }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}