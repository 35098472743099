<template>
  <div>
    <div class="top-bar d-flex align-items-center color-gray">
        <div class="w-100 d-flex align-items-center px-2">
          <div class="col-3 d-flex justify-content-center">
            <div class="d-flex button mx-1">
              <div class="arrow_lr left"></div>
              <div class="mx-3 text-nowrap" v-on:click="$emit('back')">返回</div>
            </div>
          </div>   
        </div>
    </div>
    <OrderInfoRow 
      :orderNumber="info.orderNumber"
      :ticketCount="ticketCount"
      :numTicketTaken="ticketCount-ticketRemain"
    />
    <div v-for="(item, index) in inputs.passenger" :key="index">
      <div class="top-bar d-flex align-items-center">
        <div class="w-100 d-flex align-items-center px-2">
          <div class="col-3 d-flex justify-content-center">

          </div>   
          <div class="col-6">
            乘客資訊({{ticketCount-ticketRemain+index+1}}/{{ticketCount}})
          </div>
          <div class="col-3 d-flex justify-content-end">
            <div class="btn-rounded-circle-background mx-2" v-if="index != 0">
              <button
                class="btn-close btn-close-white btn-close-custom-opca"
                aria-label="Close"
                v-on:click="btnClose(index)"
              ></button>
            </div>
          </div>
        </div>
      </div>
      <div class="p-2 mb-4 bg-white shadow-sm">
        <div class="mx-4">
          <div class="my-3">
            <div class="d-flex align-content-start text-input-describe p-2">
              乘客姓名
            </div>
            <Input
              :value="item.data.name"
              :placeholder="'請輸入乘客姓名'"
              :type="'text'"
              v-on:input="item.data.name = $event"
            />
            <div class="d-flex align-content-start p-2 pt-1 notice-color">
              <span v-if="item.notice.name">{{item.notice.name_notice}}</span>
            </div>
          </div>
          <div class="my-3">
            <div class="d-flex align-content-start text-input-describe p-2 justify-content-between">
              <div>
                <span v-if="!item.is_passport">乘客身分證字號</span> 
                <span v-if="item.is_passport">乘客護照號碼</span>
                <span class="notice-color">*</span>
              </div>
              <div class="d-flex align-items-center">
                <input class="mx-1" type="checkbox" v-model="item.is_passport">護照編號
              </div>
              
            </div>
            <Input
              :value="item.data.idcardNumber"
              :placeholder="'請輸入乘客身分證字號'"
              :type="'text'"
              v-on:input="item.data.idcardNumber = $event"
            />
            <div class="d-flex align-content-start p-2 pt-1 notice-color">
              <span v-if="item.notice.id">{{item.notice.id_notice}}</span>
            </div>
          </div>
          <div class="my-3">
            <div class="d-flex align-content-start text-input-describe p-2 justify-content-between">
              <span>手機號碼<span class="notice-color">*</span></span> 
              <div class="d-flex align-items-center" v-if="parseInt(index)!==0">
                <input class="mx-1" type="checkbox"
                 v-model="item.is_copyPassenger1"
                 v-on:change="samePassenger1(parseInt(index), item.is_copyPassenger1)">同乘客資訊1
              </div>
            </div>
            <Input
              :value="item.data.phoneNumber"
              :placeholder="'請輸入手機號碼'"
              :type="'text'"
              v-on:input="item.data.phoneNumber = $event"
            />
            <div class="d-flex align-content-start p-2 pt-1 notice-color">
              <span v-if="item.notice.phone">{{item.notice.phone_notice}}</span>
            </div>
          </div>
          <!-- <div class="my-3">
            <div class="d-flex align-content-start text-input-describe p-2">
              生日
            </div>
            <InputBlank
              :value="item.data.birthday" 
              :placeholder="'請選擇取票人生日'"
              :emit_event="'emit_showScroll'"
              @emit_showScroll="selectTargetBirthday(item.id)"
            />
            <div class="d-flex align-content-start p-2 pt-1 notice-color">
              <span v-if="item.notice.birthday">{{item.notice.birthday_notice}}</span>
            </div>
          </div> -->
          <div class="my-3">
            <div class="d-flex align-content-start text-input-describe p-2">
              選擇票種<span class="notice-color">*</span>
            </div>
            <Select
              :options="searchResult"
              :count="countTicket"
              v-on:select="item.data.fare_id = $event"
              :isFare="true"
            />
            <div class="d-flex align-content-start p-2 pt-1 notice-color">
              <span v-if="item.notice.fare">{{item.notice.fare_notice}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="mx-4" v-if="index === inputs.passenger.length-1">
        <button class="btn-next my-1" v-on:click="submit()">
          下載取票({{ticketCount-ticketRemain+index+1}}/{{ticketCount}})
        </button>
        <button
         class="btn-sub my-1 fw-bold" v-on:click="addPassenger()"
         v-if="ticketLimit"
        >
          取下一張票({{ticketCount-ticketRemain+index+1+1}}/{{ticketCount}})
        </button>
      </div>
    </div>
    <div class="mx-4 my-4">
        <TicketInfoBlock 
         v-for="(simpleTicketItem, stIndex) in ticketsInfo" :key="stIndex"
         :seq="parseInt(stIndex)+1"
         :ticketNumber="simpleTicketItem.number"
         :passengerName="simpleTicketItem.passenger_name"
         :passengerID="simpleTicketItem.passenger_id_number"
         :passengerPhone="simpleTicketItem.passenger_phone"
         :fareName="simpleTicketItem.name"
         :ticketStatus="simpleTicketItem.status"
        />
    </div>
    <div v-if="componentStatus.isShowingDateSelect">
      <CustomDateSmoothPicker 
        :headerName="'選擇生日'"
        :maxDate="getToday()"
        :minDate="getTargetMinDate()"
        :target="'birthday'"
        @birthday="scrollResult"
        :currentYear="getCurrentYear()"
        :currentMonth="getCurrentMonth()"
        :currentDate="getCurrentDate()"
      />
    </div>

  </div>
</template>
<script>
import Input from "@/components/basic-components/input/custom-input.vue";
import Select from "@/components/basic-components/input/custom-input-select.vue";
import Passenger from "@/classes/Passenger.js";
import { apiPostGetTicket } from "@/apis/download.js";
// import InputBlank from "@/components/basic-components/input/custom-input-blank.vue";
import CustomDateSmoothPicker from "@/components/basic-components/custom-date-smooth-picker.vue";
import asyncLocalStorage from '@/utils/asyncLocalStorage.js';
import OrderInfoRow from './components/orderInfoRow/orderInfoRow.vue';
import { apiPostCheckTicketStatus } from "@/apis/myTickets.js";
import TicketInfoBlock from "./components/ticketInfoBlock/ticketInfoBlock.vue";
import { apiGetSearchOrder } from "@/apis/download.js";
import { mapGetters } from 'vuex';

export default {
  components: {
    Input,
    Select,
    // InputBlank,
    CustomDateSmoothPicker,
    OrderInfoRow,
    TicketInfoBlock,
  },
  props: {
    _searchResult: Array,
    info: Object,
  },
  watch: {
    'inputs.passenger': {
      handler: function(val) {
        for ( let i = 0 ; i < val.length ; i++ ) {
          this.getMinDateAndCheckBirthday(val[i].data.fare_id, i);
        }
      },
      deep: true,
    },
    'modalTargetState': function(newV, oldV) {
      console.log(oldV,newV);
      if ( !this.$store.getters["modal/getModalConfirm"]
        && oldV === 'to-my-ticket' && newV === "" ) {
        this.$store.commit("modal/clearState");
        this.$router.push({ path: 'my-tickets' });
      }
      else if ( !this.$store.getters["modal/getModalConfirm"]
        && oldV === 'continue-or-my-ticket' && newV === "" ) {
        window.location.href = '/d?o_n=' + this.info.orderNumber + '&o_i=' + this.info.orderInfo ;
      }
    },
    'modalConfirmState': async function() {
      if ( this.$store.getters["modal/getModalConfirm"] 
        && this.$store.getters["modal/getModalTarget"]==='continue-or-my-ticket' ) {
        this.$store.commit("modal/clearState");
        this.$router.push({ path: 'my-tickets' });
      }
      else if ( this.$store.getters["modal/getModalConfirm"] 
        && this.$store.getters["modal/getModalTarget"]==='to-my-ticket' ) {
        window.location.href = '/d?o_n=' + this.info.orderNumber + '&o_i=' + this.info.orderInfo ;
      }
    },
  },
  data() {
    return {
      searchResult: {},
      componentStatus: {
        
        isShowingDateSelect: false,
        scorllTarget: 0,
      },
      inputs: {
        passenger: [],
      },
      results: {

      },
      ticketsInfo: [],
    };
  },
  computed: {
    ticketCount() {
      let count = 0 ;
      for ( let i = 0 ; i < this.searchResult.length ; i++ ) {
          count += this.searchResult[i].count; 
      }
      return count;
    },
    ticketRemain() {
      let remain = 0 ;
      for ( let i = 0 ; i < this.searchResult.length ; i++ ) {
          remain += this.searchResult[i].remain; 
      }
      return remain;
    },
    ticketLimit() {
      // max passenger ;
      let count = 0 ;
      for ( let i = 0 ; i < this.searchResult.length ; i++ ) {
          count += this.searchResult[i].remain; 
      }
      if ( this.inputs.passenger.length < count ) 
        return true;
      else 
        return false;
    },
    countTicket() {
      // count All fare selected ;
      let countList = [];
      for ( let i = 0 ; i < this.searchResult.length ; i++ ) {
        let newIDcount = {
          id: this.searchResult[i].id,
          count: 0,
          limit: this.getFareById(this.searchResult[i].id).remain,
        };
        countList.push(newIDcount);
      }

      for (let i = 0; i < this.inputs.passenger.length; i++) {
        for (let run_list = 0; run_list < countList.length; run_list++) {
          if (countList[run_list].id === parseInt(this.inputs.passenger[i].data.fare_id) ) {
            countList[run_list].count++;
          }
        }
      }
      return countList;
    },
    ...mapGetters({
      modalConfirmState: 'modal/getModalConfirm',
      modalTargetState: 'modal/getModalTarget',
      modalActiveState: 'modal/getModalActive',
    })
  },
  methods: {
    async init() {
      this.searchResult = this._searchResult;
      this.$store.commit("modal/clearState");
      this.$store.commit("header/clearHeader");
      this.$store.commit("header/setHeader", {
        header: [
          { 
            title: "取票資訊",
            type: "text",
          }
        ],
        type: "text"
      });

      this.ticketsInfo = await this.getTicketsInfo();
      this.addPassenger();
    },
    addPassenger() {
      if (this.ticketLimit) {
        this.inputs.passenger.push({
          id: this.inputs.passenger.length,
          data: new Passenger("", "", "", ""),
          is_passport: false,
          is_copyPassenger1: false,
          notice: {
            name: false, name_notice: "",
            id: false, id_notice: "",
            phone: false, phone_notice: "",
            birthday: false, birthday_notice: "",
            fare: false, fare_notice: "",
          }
        });
      }
    },
    samePassenger1( pIndex, isSame ) {
      console.log( pIndex, isSame );
      if ( isSame )
        this.inputs.passenger[pIndex].data.phoneNumber = this.inputs.passenger[0].data.phoneNumber;
    },
    submit() {
      this.componentStatus.notice = false;
      this.componentStatus.notice_msg = "";
      if ( this.validateInputs() ) {
        this.downloadTicket();
      }
    },
    async downloadTicket() {

      this.$store.commit("modal/setLoadingState");
      
      let passengerList = [];
      for ( let i = 0 ; i < this.inputs.passenger.length ; i++ ) {
        passengerList.push({
            fare_id: parseInt(this.inputs.passenger[i].data.fare_id),
            name: this.inputs.passenger[i].data.name,
            id_number: this.inputs.passenger[i].data.idcardNumber,
            phone: this.inputs.passenger[i].data.phoneNumber,
            is_passport: this.inputs.passenger[i].is_passport,
        });
      }
      let data = {
          order_number: this.info.orderNumber,
          info: this.info.orderInfo,
          passengers: passengerList,
      };
      console.log(data);
      this.results = await apiPostGetTicket(data); 
      if ( this.results.status === 200 ) {
          await this.setTicketLocalStorage(this.results.data);
          this.$store.commit("modal/clearState");

          this.updateInfo();
          this.ticketsInfo = await this.getTicketsInfo();
          if ( this.ticketRemain ) {
            this.setTakeTicketsSuccessModalHasRemainTicket();
          }
          else {
            this.setTakeTicketsSuccessModalNoRemainTicket();
          }
      }
      else if ( this.results.status === 400 ) {
        this.$store.commit("modal/setModal", {
          active: true,
          status: "fail",
          message: [
            {
              type: "text",
              content: this.results.message,
              fontWeight: "bold",
              color: "notice",
              mt: 3,
              mb: 2,
            },
            {
              type: "icon",
              content: "fail",
              fontWeight: "normal",
              color: "notice",
              mt: 2,
              mb: 3,
            },
          ],
        });
      }
      else {
        console.log('error', this.results);
        this.$store.commit("modal/setModal", {
          active: true,
          status: "fail",
          message: [
            {
              type: "text",
              content: "發生錯誤\n請稍後再試",
              fontWeight: "bold",
              color: "notice",
              mt: 3,
              mb: 2,
            },
            {
              type: "icon",
              content: "fail",
              fontWeight: "normal",
              color: "notice",
              mt: 2,
              mb: 3,
            },
          ],
        });
      }
      
    },
    async setTicketLocalStorage( resultData ) {
        // get localStorage tickets, then push download tickets ;
        let myTickets = JSON.parse(localStorage.getItem('tickets'));
        if ( myTickets == null ) 
            myTickets = [];
        for ( let i = 0 ; i < resultData.length ; i++ ) {
            myTickets.push({
                data: resultData[i],
                isUsed: false,
            });
        }
        await asyncLocalStorage.setItem("tickets", JSON.stringify(myTickets)).then(function(){
          console.log('done, now localStorage tickets:', JSON.parse(localStorage.getItem('tickets')));
        });
    },
    btnClose(index) {
      this.inputs.passenger.splice(index, 1);
    },
    getFareById(id) {
      for ( let i = 0 ; i < this.searchResult.length ; i++ ) {
          if ( this.searchResult[i].id === parseInt(id) ) {
              return this.searchResult[i] ;
          }
      }
      return null ;
    },
    getMinDateAndCheckBirthday( fare_id, index ) {
      let minDate = this.getMinDateByfareID(fare_id);
      if ( this.inputs.passenger[index].data.birthday < minDate )
        this.inputs.passenger[index].data.birthday = "";
      
      return minDate;
          
    },
    getTargetMinDate() {
      return this.getMinDateByfareID(
        this.inputs.passenger[this.componentStatus.scorllTarget].data.fare_id
      );
    },
    getMinDateByfareID( fare_id ) {
      if ( fare_id != undefined ) {
        let fare = this.getFareById(fare_id);
        // console.log(fare.name);
        let ticketType = fare.type; //.name.substring( fare.name.length-2, fare.name.length );
        // console.log(ticketType);
        if ( ticketType === 'children' ) {
          return this.getHalfTicketLimit() ;
        }
      }
      return "";
    },
    validateInputs() {
      let boolean = true ;
      for ( let i = 0 ; i < this.inputs.passenger.length ; i++ ) {
        boolean = this.validatePassenger(i);
        if ( !boolean ) 
          break;
      }

      return boolean ;
    },
    validatePassenger( index ) {

      let boolean = true ;
      // if ( this.inputs.passenger[index].data.name === "" ) {
      //   this.inputs.passenger[index].notice.name = true ;
      //   this.inputs.passenger[index].notice.name_notice = "姓名不得為空" ;
      //   boolean = false;
      // }
      // else {
      //   this.inputs.passenger[index].notice.name = false ;
      //   this.inputs.passenger[index].notice.name_notice = "" ;
      // }

      if ( !this.inputs.passenger[index].is_passport && !this.validateIDNumber(this.inputs.passenger[index].data.idcardNumber) ) {
        this.inputs.passenger[index].notice.id = true ;
        this.inputs.passenger[index].notice.id_notice = "身分證有誤" ;
        boolean = false;
      }
      else if ( this.inputs.passenger[index].is_passport && !this.validatePassport(this.inputs.passenger[index].data.idcardNumber) ) {
        this.inputs.passenger[index].notice.id = true ;
        this.inputs.passenger[index].notice.id_notice = "護照有誤" ;
        boolean = false;
      }
      else {
        this.inputs.passenger[index].notice.id = false ;
        this.inputs.passenger[index].notice.id_notice = "" ;
      }

      if ( !this.validatePhoneNumber(this.inputs.passenger[index].data.phoneNumber) ) {
        this.inputs.passenger[index].notice.phone = true ;
        this.inputs.passenger[index].notice.phone_notice = "手機有誤" ;
        boolean = false;
      }
      else {
        this.inputs.passenger[index].notice.phone = false ;
        this.inputs.passenger[index].notice.phone_notice = "" ;
      }
      
      // if ( this.inputs.passenger[index].data.birthday === "" ) {
      //   this.inputs.passenger[index].notice.birthday = true ;
      //   this.inputs.passenger[index].notice.birthday_notice = "生日不得為空" ;
      //   boolean = false;
      // }
      // else {
      //   this.inputs.passenger[index].notice.birthday = false ;
      //   this.inputs.passenger[index].notice.birthday_notice = "" ;
      // }

      if ( this.inputs.passenger[index].data.fare_id === undefined ) {
        this.inputs.passenger[index].notice.fare = true ;
        this.inputs.passenger[index].notice.fare_notice = "請選擇票種" ;
        boolean = false;
      }
      else {
        this.inputs.passenger[index].notice.fare = false ;
        this.inputs.passenger[index].notice.fare_notice = "" ;
      }
      return boolean;
      
    },
    selectTargetBirthday( target_id ) {
      
      this.componentStatus.scorllTarget = parseInt(target_id);
      this.componentStatus.isShowingDateSelect = true;


    },
    getCurrentYear() {
      return parseInt(new Date(this.inputs.passenger[this.componentStatus.scorllTarget].data.birthday).getFullYear());
    },
    getCurrentMonth() {
      return parseInt(new Date(this.inputs.passenger[this.componentStatus.scorllTarget].data.birthday).getMonth()+1);
    },
    getCurrentDate() {
      return parseInt(new Date(this.inputs.passenger[this.componentStatus.scorllTarget].data.birthday).getDate());
    },
    scrollResult( result ) {

      // TODO: result 若允許為空 則須加額外判斷避免直接空值覆蓋既有數值 ;
      this.inputs.passenger[this.componentStatus.scorllTarget].data.birthday = result;

      this.componentStatus.isShowingDateSelect = false;
    },
    async getTicketsInfo() {
      this.$store.commit("modal/setLoadingState");
      let postResult = await apiPostCheckTicketStatus({
        query: this.getUsedORPickedTicketsDataFromSimpleInfo(),
      });
      console.log(postResult);
      if ( postResult.status === 200 ) {
        this.$store.commit("modal/clearState");
        return postResult.data ;
      }
      else {
        this.$store.commit("modal/clearState");
        return [];
      }
    },
    async updateInfo() {
      this.$store.commit("modal/setLoadingState");
      let data = {
        order_number: this.info.orderNumber,
        info: this.info.orderInfo,
      };
      let results = await apiGetSearchOrder(data);
      console.log(results)
      if ( results.status === 200 ) {
        console.log("get order data");
        this.$store.commit("modal/clearState");
        this.searchResult = results.data.fares ;
      } 
      else {
        this.$store.commit("modal/clearState");
        this.$store.commit("modal/setModal", {
          active: true,
          status: "fail",
          message: [
            {
              type: "text",
              content: "發生錯誤",
              fontWeight: "bold",
              color: "notice",
              mt: 3,
              mb: 0,
            },
            {
              type: "icon",
              content: "fail",
              fontWeight: "normal",
              color: "",
              mt: 3,
              mb: 3,
            },
            {
              type: "text",
              content: "更新訂單資料時發生錯誤",
              fontWeight: "normal",
              color: "notice",
              mt: 3,
              mb: 0,
            },
            {
              type: "text",
              content: "請稍後再試",
              fontWeight: "normal",
              color: "notice",
              mt: 0,
              mb: 3,
            },
          ],
        });
      }
    },
    getUsedORPickedTicketsDataFromSimpleInfo() {
      // get Used or Picked up Tickets Data from SimpleInfo ;
      let array = [];
      for ( let fareIndex = 0 ; fareIndex < this.searchResult.length ; fareIndex++ ) {
        for ( let simpleInfoIndex = 0 ;
              simpleInfoIndex < this.searchResult[fareIndex].simple_ticket_infos.length ;
              simpleInfoIndex++ )
        {
          let temp = {
            order_number: this.info.orderNumber,
            ticket_number: "",
            auth: "",
          } ;
          if ( this.searchResult[fareIndex].simple_ticket_infos[simpleInfoIndex].out !== undefined
            && ( this.searchResult[fareIndex].simple_ticket_infos[simpleInfoIndex].out.status === 'picked_up' 
              || this.searchResult[fareIndex].simple_ticket_infos[simpleInfoIndex].out.status === 'used' ) ) {
            temp.ticket_number = this.searchResult[fareIndex].simple_ticket_infos[simpleInfoIndex].out.number ;
            temp.auth = this.searchResult[fareIndex].simple_ticket_infos[simpleInfoIndex].out.auth ; 
          }
          if ( this.searchResult[fareIndex].simple_ticket_infos[simpleInfoIndex].in !== undefined 
            && this.searchResult[fareIndex].simple_ticket_infos[simpleInfoIndex].in.status === 'used' ) {
            temp.ticket_number = this.searchResult[fareIndex].simple_ticket_infos[simpleInfoIndex].in.number ;
            temp.auth = this.searchResult[fareIndex].simple_ticket_infos[simpleInfoIndex].in.auth ; 
          }
          if ( temp.ticket_number !== "" ) {
            array.push(temp);
          }
        }
      }
      return array;
    },
    setTakeTicketsSuccessModalHasRemainTicket() {
      this.$store.commit("modal/setModal", {
        active: true,
        status: "select",
        message: [
          {
            type: "text",
            content: "取票成功",
            fontWeight: "bold",
            color: "maincolor",
            mt: 2,
            mb: 2,
          },
          {
            type: "icon",
            content: "success",
            fontWeight: "normal",
            color: "maincolor",
            mt: 2,
            mb: 2,
          },
          {
            type: "text-array",
            content: [
              {
                bold: false,
                underline: false,
                text: "請至",
                color: "maincolor",
              },
              {
                bold: true,
                underline: false,
                text: "「我的票卷」",
                color: "maincolor",
              },
              {
                bold: false,
                underline: false,
                text: "查看",
                color: "maincolor",
              },
            ],
            fontWeight: "normal",
            color: "maincolor",
            mt: 0,
            mb: 1,
          },
          {
            type: "text-array",
            content: [
              {
                bold: false,
                underline: false,
                text: "您還有",
                color: "maincolor",
              },
              {
                bold: true,
                underline: true,
                text: `${this.ticketRemain}`,
                color: "maincolor",
              },
              {
                bold: false,
                underline: false,
                text: "張票未取",
                color: "maincolor",
              },
            ],
            fontWeight: "normal",
            color: "maincolor",
            mt: 0,
            mb: 1,
          },
          {
            type: "text-array",
            content: [
              {
                bold: false,
                underline: false,
                text: "歡迎您將訂票編號",
                color: "maincolor",
              },
              {
                bold: true,
                underline: true,
                text: this.info.orderNumber,
                color: "maincolor",
              },
            ],
            fontWeight: "normal",
            color: "maincolor",
            mt: 0,
            mb: 1,
          },
          {
            type: "text-array",
            content: [
              {
                bold: false,
                underline: false,
                text: "和訂票人電話",
                color: "maincolor",
              },
              {
                bold: true,
                underline: true,
                text: this.info.orderInfo,
                color: "maincolor",
              },
              {
                bold: false,
                underline: false,
                text: "交由其他人取票",
                color: "maincolor",
              },
            ],
            fontWeight: "normal",
            color: "maincolor",
            mt: 0,
            mb: 2,
          },
        ],
        target: "continue-or-my-ticket",
        options: [
          "繼續取此訂單剩餘票卷",
          "至我的船票查看"
        ],
      });

    },
    setTakeTicketsSuccessModalNoRemainTicket() {
      this.$store.commit("modal/setModal", {
        active: true,
        status: "select",
        message: [
          {
            type: "text",
            content: "取票成功",
            fontWeight: "bold",
            color: "maincolor",
            mt: 2,
            mb: 2,
          },
          {
            type: "icon",
            content: "success",
            fontWeight: "normal",
            color: "maincolor",
            mt: 2,
            mb: 2,
          },
          {
            type: "text-array",
            content: [
              {
                bold: false,
                underline: false,
                text: "您的票卷皆已取完",
                color: "maincolor",
              },
            ],
            fontWeight: "normal",
            color: "maincolor",
            mt: 0,
            mb: 1,
          },
          {
            type: "text-array",
            content: [
              {
                bold: false,
                underline: false,
                text: "請至",
                color: "maincolor",
              },
              {
                bold: true,
                underline: false,
                text: "「我的票卷」",
                color: "maincolor",
              },
              {
                bold: false,
                underline: false,
                text: "查看",
                color: "maincolor",
              },
            ],
            fontWeight: "normal",
            color: "maincolor",
            mt: 0,
            mb: 1,
          },
        ],
        target: "to-my-ticket",
        options: [
          "至我的船票查看",
          "停留在本頁"
        ],
      });

    },
  },
  async mounted() {
    await this.init();
  },
};
</script>
