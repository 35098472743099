<template>
  <span
    class="notice-color has-link-hover d-flex align-items-center justify-content-center"
    v-on:click="editClick()"
  ><span class="mx-1 text-nowrap fs-0p8em">{{name}}</span>
    <div
     class="mask-image-style icon"
    :style="maskImageStyleObject(require('@/assets/common/icons/icon_edit.svg'))"
    >
    </div>
  </span>
</template>
<script>
export default {
    props: {
        name: String,
    },
    methods: {
        editClick() {
            this.$store.commit("modal/setModal", {
                active: true,
                status: "confirm",
                message: [
                    {
                        type: "text",
                        content: "修改資訊",
                        fontWeight: "bold",
                        color: "maincolor",
                        mt: 2,
                        mb: 2,
                    },
                    {
                        type: "icon",
                        content: "edit",
                        fontWeight: "normal",
                        color: "maincolor",
                        mt: 3,
                        mb: 3,
                    },
                    {
                        type: "text-array",
                        content: [
                            {
                                bold: false,
                                underline: false,
                                text: "請透過",
                                color: "maincolor",
                            },
                            {
                                bold: true,
                                underline: true,
                                text: "即時客服",
                                color: "maincolor",
                            }
                        ],
                        fontWeight: "normal",
                        color: "maincolor",
                        mt: 2,
                        mb: 0,
                    },
                    {
                        type: "text",
                        content: "聯繫專人處理",
                        fontWeight: "normal",
                        color: "maincolor",
                        mt: 2,
                        mb: 0,
                    },
                    
                ],
                options: [],
            });
        },
    }
}
</script>
