<template>
    <div 
     class="ticket-simple"
     :class="{'bg-used':ticketStatus==='used'}"
    >
        <div class="header-row">
            <div class="ticket-status">
                {{ statusText }}
            </div>
            <div class="w-50 d-flex justify-content-between align-items-center">
                {{ $t('passenger-info') }}{{ seq }}
                <EditBtn
                 v-if="mainTypeDisableEditBtn"
                 :name="'修改'" 
                />
            </div>
            
        </div>
        
        <div class="mid justify-content-between">
          <div class="mid-left"></div>
          <hr/>
          <div class="mid-right"></div>
        </div>

        <div class="body-row">
            <div class="d-flex my-2">
                <div class="w-50">
                    <div class="title">
                        {{ $t("passenger-info-title-name") }}
                    </div>
                    <div class="content">{{ passengerName }}
                    </div>
                </div>
                <div class="w-50">
                    <div class="title">
                        {{ $t("passenger-info-title-id") }}
                    </div>
                    <div class="content">{{ passengerID }}
                    </div>
                </div>
            </div>
            <div class="d-flex my-3">
                <div class="w-50">
                    <div class="title">
                        {{ $t("passenger-info-title-phone") }}
                    </div>
                    <div class="content">{{ passengerPhone }}
                    </div>
                </div>
                <div class="w-50">
                    <div class="title">
                        {{ $t("passenger-info-title-fare") }}
                    </div>
                    <div class="content">{{ fareName }}
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import EditBtn from '@/components/basic-components/btn-edit/btn-edit.vue';
export default {
    props: {
        seq: Number,
        ticketNumber: String,
        passengerName: String,
        passengerID: String,
        passengerPhone: String,
        fareName: String,
        ticketStatus: String,
    },
    components: {
        EditBtn,
    },
    computed: {
        statusText() {
            if ( this.ticketStatus === 'picked_up' )
                return this.$t('status-picked-up');
            else if ( this.ticketStatus === 'used' )
                return this.$t('status-used');
            else 
                return "" ;
        },
        mainTypeDisableEditBtn() {
            let disableList = [
                'M',
            ];
            for ( let i = 0 ; i < disableList.length ; i++ ) {
                if ( this.ticketNumber.substring( 2, 3 ) === disableList[i] ) {
                    return false ;
                }
            }
            return true ;
        }
    },
}
</script>
<style scoped>
@import './style/ticketInfoBlock.css';
</style>
<i18n>
{
    "en" : {
        "status-picked-up": "已取",
        "status-used": "已使用",
        "passenger-info": "乘客資訊",
        "passenger-info-title-name": "乘客姓名",
        "passenger-info-title-id": "身分證字號(護照號碼)",
        "passenger-info-title-phone": "手機號碼",
        "passenger-info-title-fare": "票種"
    },
    "zh-TW" : {
        "status-picked-up": "已取",
        "status-used": "已使用",
        "passenger-info": "乘客資訊",
        "passenger-info-title-name": "乘客姓名",
        "passenger-info-title-id": "身分證字號(護照號碼)",
        "passenger-info-title-phone": "手機號碼",
        "passenger-info-title-fare": "票種"
    }
}
</i18n>