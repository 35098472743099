<template>
    <div class="header header-wrapper">
      <div class="text-nowrap">
        <div v-if="this.$store.getters['header/getHeaderType']==='text'">
          <span class="title">{{this.$store.getters['header/getHeaderTitle']}}</span>
        </div>
        <nav class="nav" v-if="this.$store.getters['header/getHeaderType']==='link'">
          <ul class="d-flex p-0 m-0">
            <ol 
             class="p-0 nav-item"
             :class="{'mx-3': parseInt(index)>0}"
             v-for="(item, index) in this.$store.getters['header/getHeader']" :key="index">
              <router-link 
               :to="item.href" 
               class="title link text-white"
               :class="{'active': $route.query.status === getStatus(item.href)}"
              >{{item.title}}</router-link>
            </ol>
          </ul>
        </nav>
      </div>
      <img 
       :src="envGetImagePath( 'header_logo.png')" 
        class="header-logo"
      >
    </div>
</template>
<script>
export default {
  methods: {
    getStatus( hrefLink ) {
      return hrefLink.substring(8,hrefLink.length);
    }
  }
}
</script>
<style scoped>
@import './style/header.css';
</style>
