<template>
  <div class="about">
    <h1>This is an about page</h1>
  </div>
</template>
<script>
export default {
  methods: {
    init() {
      this.$store.commit('button/changeButtonActive',{
        id: 4,
      });
    }
  },
  mounted(){
    this.init();
  },
}
</script>