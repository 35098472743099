module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "zh-TW": {
        "outbound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["去程"])},
        "inbound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回程"])},
        "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["失效"])},
        "title-ticket-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["票種"])},
        "empty-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您尚未訂購任何票卷"])}
      },
      "en": {
        "outbound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["去程"])},
        "inbound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回程"])},
        "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["失效"])},
        "title-ticket-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["票種"])},
        "empty-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["empty"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"zh-TW":{"outbound":"去程","inbound":"回程","expired":"失效","title-ticket-type":"票種","empty-message":"您尚未訂購任何票卷"},"en":{"outbound":"去程","inbound":"回程","expired":"失效","title-ticket-type":"票種","empty-message":"empty"}}')
  delete Component.options._Ctor
  
}
