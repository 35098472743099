<template>
  <div id="app" :style="cssProps">
    <div class="base">
      <!-- <Header/> -->
      <div class="view-layout pb-4"> 
        <router-view />
      </div>   
      <BotNavbar />
      <Modal
      />
    </div>
  </div>
</template>
<script>
import BotNavbar from "./components/layout-components/bottom-nav-bar/bottom-nav-bar.vue";
import Modal from "@/components/basic-components/modal/modal.vue";

export default {
  components: {
    BotNavbar,
    Modal,
  },
  methods: {
    init() {
      // document.title = getEnv('VUE_APP_TITLE');
    },
  },
  computed: {
    cssProps: function() {
      return this.envGetStylePropPath();
    }
  },
  mounted() {
    this.init();
  },
};
</script>
