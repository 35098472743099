export default {
    methods: {
      validateIDNumber( userid ) { //身份證檢查函式
        var reg=/^[A-Z]{1}[1-2]{1}[0-9]{8}$/;  //身份證的正規表示式
        if( reg.test( userid ) ) {
            var s = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";  //把A取代成10,把B取代成11...的作法
            var ct = ["10","11","12","13","14","15","16","17","34","18","19","20","21",
                           "22","35","23","24","25","26","27","28","29","32","30","31","33"];
        var i = s.indexOf(userid.charAt(0));
        var tempuserid = ct[i] + userid.substr(1, 9); //若此身份證號若是A123456789=>10123456789
        var num = tempuserid.charAt(0)*1;
        for( i=1 ; i<=9 ; i++ ) {
           num = num + tempuserid.charAt(i)*(10-i);
        }
        num += tempuserid.charAt(10)*1;
     
            if( (num%10)==0 ) {
               return true;
            }
            else {
               return false;
            }
         }
         else {
            return false;
        }
    },
        // validateIDNumber(id) {
        //     id = id.trim();
        
        //     if (id.length != 10) {
        //         console.log("Fail，長度不正確");
        //         return false
        //     }
        
        
        //     let countyCode = id.charCodeAt(0);
        //     if (countyCode < 65 | countyCode > 90) {
        //         console.log("Fail，字首英文代號，縣市不正確");
        //         return false
        //     }
        
        //     let genderCode = id.charCodeAt(1);
        //     if (genderCode != 49 && genderCode != 50) {
        //         console.log("Fail，性別代碼不正確");
        //         return false
        //     }
        
        //     let serialCode = id.slice(2)
        //     for (let i in serialCode) {
        //         let c = serialCode.charCodeAt(i);
        //         if (c < 48 | c > 57) {
        //             console.log("Fail，數字區出現非數字字元");
        //             return false
        //         }
        //     }
        
        //     let conver = "ABCDEFGHJKLMNPQRSTUVXYWZIO"
        //     let weights = [1, 9, 8, 7, 6, 5, 4, 3, 2, 1, 1]
        
        //     id = String(conver.indexOf(id[0]) + 10) + id.slice(1);
        
        //     checkSum = 0
        //     for (let i = 0; i < id.length; i++) {
        //         c = parseInt(id[i])
        //         w = weights[i]
        //         checkSum += c * w
        //     }
        
        //     verification = checkSum % 10 == 0
        
        //     if (verification) {
        //         console.log("Pass");
        //     } else {
        //         console.log("Fail，檢核碼錯誤");
        //     }
        
        //     return verification
        // },
        validatePhoneNumber(phoneStr) {
            if ( phoneStr === "" ) return false
            var MobileReg = /^(09)[0-9]{8}$/;
            return (phoneStr.match(MobileReg)) ? true : false
        },
        validatePassport(passportStr) {
            const patt = /^[0-9A-Z]{4,15}$/ ;
            if (patt.test(String(passportStr).toUpperCase()) ) {
                return true;
            }
            else {
                console.log("Passport is not valid");
            }
            return false;
        },
        validateEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        }

    }
    
}