<template>
  <div>
    <div class="top-bar d-flex align-items-center">
      <div class="w-100 d-flex align-items-center px-2">
        <div class="col-3 d-flex justify-content-center">
          <div class="d-flex button mx-1">
            <div class="arrow_lr left"></div>
            <div class="mx-3 text-nowrap" v-on:click="clickBack()">返回</div>
          </div>
          
        </div>
        <div class="col-6">
          <span>{{componentStatus.station.from}}</span> -> <span>{{componentStatus.station.to}}</span>
        </div>
        <div class="col-3 fs-0p7em">
           {{ componentStatus.date }}
           {{ getWeekday(componentStatus.date) }}
        </div>
            
      </div>
    </div>
        
        <div class="ship-list pb-3">
            <div 
              v-for="(item,index) in componentStatus.shipList" :key="index"
              class="content"
              v-on:click="selectShipTime(item.data.time, item.disable)"
            >
                <div class="d-flex align-items-center">
                    <div class="text-black-50 col-3">東琉線</div> 
                    <div 
                      class="ship-time d-flex justify-content-center align-items-center col-6"
                      :class="{ disabled: item.disable }"
                    >
                        <span class="d-block">
                            <div class="time-content">
                              <div>
                                {{item.data.time}}
                              </div> 
                              <div class="special" v-if="item.isSpecial">機動加開</div>
                            </div>
                            
                        </span>
                        
                    </div>
                    <div 
                      v-if="item.isFull"
                      class="notice-color col-3"
                    >
                        額滿
                    </div>
                    <div v-else class="col-3">
                        
                    </div>
                </div>
                <div>
                    <hr class="mx-auto w-88-opacity my-0 border-dark" />
                </div>
            </div>
            
        </div>
        <div class="notice-color">
          實際船班狀況以現場安排為主
        </div>
    </div>
</template>
<script>
import { apiGetSailings } from "@/apis/booking.js";

export default {

  props: {
    _departureDate: String,
    _returnDate: String,
    _route: String,
    _from: String,
    _to: String,
    _ticketCount: Number,
  },
  data() {
    return {
      componentStatus: {
        date: "",
        routeCode: "DL", 
        shipList: [],
        station: {
          from: "",
          to: "",  
        },
        selecting: "departure", // "return" ;
      },
      componentData: {
        departure: {
            date: "",
            time: "",
        },
        return: {
            date: "",
            time: "",
        }
      },
    };
  },
  watch: {
    'componentStatus.shipList': function() {
      this.isLoadEnd();
    },
  },
  methods: {
    isLoadEnd() {
      if ( this.componentStatus.shipList.length === 0 ) {
        this.$store.commit("modal/setLoadingState");
      }
      else {
        this.$store.commit("modal/clearState");
      }
    },
    selectShipTime( time, isDisable ) {
      // 選擇去程或回程的航班 ;

      if ( !isDisable && this.checkTime( this.componentStatus.date, time ) ) {

        if ( this.componentStatus.selecting === 'departure' ) {
            this.componentData.departure.date = this.componentStatus.date;
            this.componentData.departure.time = time;
            this.componentStatus.shipList = []; // 選完清空船班
            if ( this._returnDate !== null ) {
                // 預備之後可能賣單程票的情況,
                // 以有無輸入returnDate做區分 ;
                this.changeSelecting( 'return' );
            }
            else {
                // 單程票直接emit結果 ;
                this.emitSelectResult();
            }
            
        }
        else if ( this.componentStatus.selecting === 'return' ) {
            this.componentData.return.date = this.componentStatus.date;
            this.componentData.return.time = time;

            this.emitSelectResult();
        }

      }


    },
    emitSelectResult() {
        this.$emit('select-shiptime', this.componentData);
    },
    clickBack() {
        if ( this.componentStatus.selecting === 'departure' ) {
            this.$emit('back', 'work');
        }
        else if ( this.componentStatus.selecting === 'return' ) {
            this.changeSelecting( 'departure' );
        }
        this.componentStatus.shipList = []; // 選完清空船班
    },
    async updateComponentStatus( date, route ) {
      // 傳入date, routeCode, 來更新componentStatus ;
      this.componentStatus.date = date;
      this.componentStatus.routeCode = route;
      await this.getShip( this.componentStatus.date, this.componentStatus.routeCode )
    },
    routeReverse( routeCode ) {
        // Step 1. Use the split() method to return a new array
        let splitString = routeCode.split(""); // var splitString = "hello".split("");
        // ["h", "e", "l", "l", "o"]
    
        // Step 2. Use the reverse() method to reverse the new created array
        let reverseArray = splitString.reverse(); // var reverseArray = ["h", "e", "l", "l", "o"].reverse();
        // ["o", "l", "l", "e", "h"]
    
        // Step 3. Use the join() method to join all elements of the array into a string
        let joinArray = reverseArray.join(""); // var joinArray = ["o", "l", "l", "e", "h"].join("");
        // "olleh"
        
        //Step 4. Return the reversed string
        return joinArray; // "olleh"
    },
    async getShip( date, routeCode ) {
      // api 取得 航班時間;
      let data = {
        date: date,
        route_code: routeCode,
      }

      let searchResult = await apiGetSailings(data);
      if ( searchResult.status === 200 ) {
        this.componentStatus.shipList = []; // 先清空原有的船班
        for ( let i = 0 ; i < searchResult.data.length ; i++ ) {
          // console.log(searchResult.data[i]);
          let oneRow = {
              data: searchResult.data[i],
              disable: false,
              isSpecial: false,
              isFull: false,
          };
          oneRow.disable = !this.checkTime( date, oneRow.data.time );
          // if ( oneRow.data.booking + this._ticketCount >= oneRow.data.quota ) {
          //     oneRow.disable = true;
          //     oneRow.isFull = true;
          // }
          if ( oneRow.data.type === 'special' 
            || oneRow.data.type === 'speical' ) { // 'speical' 是錯誤拼，等api端有做修正再移除此判斷 ;
            oneRow.isSpecial = true;
          }

          this.componentStatus.shipList.push(oneRow);
        }
      }
      else {
        console.log(searchResult);
      }
    },
    checkTime( date, time ) {
        // 檢查輸入的時間是否過期(超過現在時間) ;
        if ( date > this.getToday() ) {
            return true ;
        }
        else if ( date === this.getToday() && time > this.getNowTime()) {
            return true ;
        }
        return false;
    },
    init() {
      // mounted - 起始初始化所執行的步驟 ;
      this.isLoadEnd();
      this.changeSelecting( 'departure' );
    },
    async changeSelecting( selecting ) {
      if ( selecting === 'departure' ) {
        this.$store.commit("header/clearHeader");
        this.$store.commit("header/setHeader", {
            header: [
              { 
                title: "選擇去程航班",
                type: "text",
              }
            ],
            type: "text"
        });
        this.componentStatus.selecting = 'departure';
        this.componentStatus.station.from = this._from;
        this.componentStatus.station.to = this._to;
        this.updateComponentStatus( this._departureDate, this._route );
      }
      else if ( selecting === 'return' ) {
        this.$store.commit("header/clearHeader");
        this.$store.commit("header/setHeader", {
            header: [
              { 
                title: "選擇回程航班",
                type: "text",
              }
            ],
            type: "text"
        });
        this.componentStatus.selecting = 'return';
        this.componentStatus.station.from = this._to;
        this.componentStatus.station.to = this._from;
        await this.updateComponentStatus( this._returnDate, this.routeReverse(this._route) );
        this.blockReturnShipBeforeDepartureShip();
      }
    },
    blockReturnShipBeforeDepartureShip() {

      if ( this._departureDate === this._returnDate ) {
        // console.log(this.componentStatus.shipList.length);
        for ( let i = 0 ; i < this.componentStatus.shipList.length ; i++ ) {
          // console.log( this.componentData.departure.time, this.componentStatus.shipList[i].data.time );
          if ( this.componentData.departure.time > this.componentStatus.shipList[i].data.time ) {
            this.componentStatus.shipList[i].disable = true;
          }

        }


      }

    },
    convertJsonObjectsToArray( json ) {
      let array = [];
      for ( let i = '0' ; json[i] !== undefined ; i=(parseInt(i)+1).toString) {
        array.push(json[i]);
      }
    }

  },
  mounted() {
    this.init();
  }
};
</script>
<style scope>
@import './style/shippingSelect.css';
</style>
