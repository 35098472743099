var render = function render(){var _vm=this,_c=_vm._self._c;return (this.$store.getters['modal/getModalActive'])?_c('div',{staticClass:"global-modal-cover",on:{"click":function($event){return _vm.onclick()}}},[_c('div',{staticClass:"gray-background-full"}),_c('div',{staticClass:"d-flex justify-content-center modal align-items-center"},[_c('div',{staticClass:"status-bubble p-2"},[_c('div',[_vm._l((_vm.trimNullContentItem(this.$store.getters['modal/getModalMessage'])),function(item,index){return _c('div',{key:index,staticClass:"text-pre-wrap",class:{
           'mt-1': item.mt === 1, 'mt-2': item.mt === 2, 'mt-3': item.mt === 3, 'mt-4': item.mt === 4,
           'mb-1': item.mb === 1, 'mb-2': item.mb === 2, 'mb-3': item.mb === 3, 'mb-4': item.mb === 4,
         }},[(item.type==='text')?_c('span',{class:{
             'text-fw-bold': item.fontWeight === 'bold',
             'text-fw-underline': item.fontWeight === 'underline',
             'text-fw-normal': item.fontWeight === 'normal',
             'text-color-maincolor': item.color === 'maincolor',
             'text-color-notice': item.color === 'notice',
           }},[_vm._v(_vm._s(item.content)+" ")]):_vm._e(),(item.type==='icon')?_c('div',[(item.content==='loading')?_c('div',[_vm._m(0,true)]):_vm._e(),(item.content==='success')?_c('div',[_c('div',{staticClass:"mask-image-style img-success mx-auto",style:(_vm.maskImageStyleObject(require('@/assets/common/icons/icon_success.svg')))})]):_vm._e(),(item.content==='fail')?_c('div',[_c('img',{staticStyle:{"height":"30%","width":"30%"},attrs:{"src":require("@/assets/common/icons/icon_error.svg")}})]):_vm._e(),(item.content==='edit')?_c('div',[_c('div',{staticClass:"mask-image-style img-edit mx-auto",style:(_vm.maskImageStyleObject(require('@/assets/common/icons/icon_edit.svg')))})]):_vm._e()]):_vm._e(),(item.type==='text-array')?_c('span',_vm._l((item.content),function(contentItem,contentIndex){return _c('p',{key:contentIndex,staticClass:"d-inline",class:{
                'fw-bold': contentItem.bold,
                'text-color-maincolor': contentItem.color === 'maincolor',
                'text-color-notice': contentItem.color === 'notice',
             }},[(contentItem.underline)?_c('ins',{staticClass:"mx-1"},[_vm._v(_vm._s(contentItem.text))]):_c('span',[_vm._v(_vm._s(contentItem.text))])])}),0):_vm._e()])}),(this.$store.getters['modal/getModalStatus']==='select')?_c('div',{staticClass:"my-3 mx-3"},[_c('div',[_c('button',{staticClass:"btn btn-next w-100 my-1",on:{"click":function($event){return _vm.clickCancel()}}},[_vm._v(" "+_vm._s(this.$store.getters['modal/getModalOptions'][0] !== undefined ? this.$store.getters['modal/getModalOptions'][0] : "取消")+" ")])]),_c('div',[_c('button',{staticClass:"btn btn-sub w-100 my-1",on:{"click":function($event){return _vm.clickConfirm()}}},[_vm._v(" "+_vm._s(this.$store.getters['modal/getModalOptions'][1] !== undefined ? this.$store.getters['modal/getModalOptions'][1] : "確定")+" ")])])]):_vm._e(),(this.$store.getters['modal/getModalStatus']==='confirm')?_c('div',{staticClass:"my-3 d-flex justify-content-center mx-3"},[_c('button',{staticClass:"btn btn-next w-100 my-1",on:{"click":function($event){return _vm.clickCancel()}}},[_vm._v(" "+_vm._s(this.$store.getters['modal/getModalOptions'][0] !== undefined ? this.$store.getters['modal/getModalOptions'][0] : "確定")+" ")])]):_vm._e()],2)])])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spinner-border text-color-maincolor",attrs:{"role":"status"}},[_c('span',{staticClass:"visually-hidden"},[_vm._v("Loading...")])])
}]

export { render, staticRenderFns }