<template>
  <div>
    <TicketBoard/>
  </div>
</template>
<script>
import TicketBoard from '@/components/pages/myTicketsPage/tickets-board/tickets-board.vue';
export default {
  components: { 
    TicketBoard

  },
  methods: {
    init() {
      this.$store.commit("button/changeButtonActive", {
        id: 0,
      });
    },

  },
  mounted() {
    this.init();
  },
};
</script>
<style scope>


</style>
