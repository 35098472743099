import Vue from 'vue'
import VueRouter from 'vue-router'

import MyTickets from '../views/MyTickets.vue'
import Booking from '../views/Booking.vue'
import Order from '../views/Order.vue'
import Download from '../views/Download.vue'
import More from '../views/More.vue'

import BeeQRID from '@/components/pages/bee/beeIdAssign.vue';

import ServiceTerms from '@/components/pages/terms/serviceTerms.vue';
import PrivacyTerms from '@/components/pages/terms/privacyTerms.vue';

import getEnv from '@/utils/env.js';
import stringCompare from '@/utils/stringCompare.js';

Vue.use(VueRouter)

const routes = [];

const enableFunctionList = getEnv('VUE_APP_APP_ENABLE_FUNCTION');
if ( stringCompare( 'my-tickets', enableFunctionList, ',' ) ) {
  routes.push({
    path: '/my-tickets',
    name: 'MyTickets',
    component: MyTickets,
  });
}

if ( stringCompare( 'download', enableFunctionList, ',' ) ) {
  routes.push({
    path: '/download',
    alias: '/d',
    name: 'Download',
    component: Download
  });
}

if ( stringCompare( 'order', enableFunctionList, ',' ) ) {
  routes.push({
    path: '/order',
    name: 'Order',
    component: Order
  });
  routes.push({
    path: '/order/:order_number/:info',
    name: 'OrderDetail',
    component: Order
  })
}

if ( stringCompare( 'booking', enableFunctionList, ',' ) ) {
  routes.push({
    path: '/booking',
    name: 'Booking',
    component: Booking
  });
  routes.push({
    path: '/bee/:uuid',
    name: 'Bee-UUIDassign',
    component: BeeQRID,
  });
  routes.push({ 
    path: '/bee', 
    redirect: '/booking' 
  });  
  routes.push({
    path: '/terms/service',
    name: 'service-terms',
    component: ServiceTerms,
  });
  routes.push({
    path: '/terms/privacy',
    name: 'privacy-terms',
    component: PrivacyTerms,
  });
}

if ( stringCompare( 'more', enableFunctionList, ',' ) ) {
  routes.push({
    path: '/more',
    name: 'More',
    component: More
  });
}

if ( !stringCompare( 'booking', enableFunctionList, ',' ) ) {
  routes.push({
    path: '/',
    redirect: '/my-tickets' 
  });
  routes.push({
    path: '/*',
    redirect: '/my-tickets'
  });
}
else {
  routes.push({
    path: '/',
    redirect: '/booking' 
  });
  routes.push({
    path: '/*',
    redirect: '/booking'
  });
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
