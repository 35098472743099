const state = () => ({
    header: [
        {
            title: "藍白航運",
            type: "text",
        }
    ],
    type: "text"
});
/*  
    example text:
    header: [
        {
            title: "title1",
            type: "text",
        }
    ],
    type: "text",
    example link:
    header: [
        {
            title: "tab1",
            type: "link",
            href: "?status=1",
        },
        {
            title: "tab2",
            type: "link",
            href: "?status=2",
        }
    ],
    type: link,
*/
const getters = {
    getHeaderTitle: (state) => {
        return state.header[0].title;
    },
    getHeaderType: (state) => {
        return state.type;
    },
    getHeader: (state) => {
        return state.header;
    },
};
  
const actions = {};
  
const mutations = {
    setHeader(state, _state) {
        state.header = _state.header;
        state.type = _state.type;
    },
    clearHeader(state) {
        state.header = [];
        state.type = "text";
    }
};
  
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}