export default function stringCompare( keyword, string, splitBy ) {

    let stringStr = string + "";
    let array = stringStr.split(splitBy);
    let boolean = false ;
    array.forEach( function(element) {
        if ( element === keyword ) {
            boolean = true;
        }
    } );

    return boolean ;
}