import request from '@/utils/request.js';

export const apiPostCheckTicketStatus =
    data => request("post",'/api/v1/ticket/my-ticket',data)
            .then(function(res){
                // console.log('apiPostCheckTicketStatus:',res.data);
                return res.data ;
            });

export const apiPostGetTicketQRCode =
    data => request("post",'/api/v1/ticket/get-ticket-qrcode',data)
            .then(function(res){
                // console.log('apiPostGetTicketQRCode:',res.data);
                return res.data ;
            });