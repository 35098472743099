<template>
  <span
    class="notice-color has-link-hover"
    v-on:click="copyToClipBoard(input)"
  >
    <span class="mx-1">複製編號</span>
    <img
     class="mask-image-style icon"
    :style="maskImageStyleObject(require('@/assets/common/icons/icon_copy.svg'))"
    />

  </span>
</template>
<script>
export default {
    props: {
        input: String,
    },
    methods: {
        copyToClipBoard(number) {
            navigator.clipboard.writeText(number)
            .then(
                () => {
                    console.log("Text copied to clipboard...")
                }
            ).catch(
                err => {
                    console.log('Something went wrong', err);
            });
        },
    }
}
</script>
