export default {
    methods: {
        maskImageStyleObject( path ){
            return {
                'mask-image': `url("${path}")`,
                '-webkit-mask-image': `url("${path}")`,
            }
        }
    }

}