module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "zh-TW": {
        "title-ticket-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["票種"])},
        "title-ticket-expire-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["票卷當日有效"])},
        "title-ferry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["船班資訊"])},
        "title-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期"])},
        "title-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["航班時間"])},
        "title-passenger-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["乘客資訊"])},
        "title-passenger-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身分證字號"])},
        "title-passenger-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聯絡電話"])},
        "title-order-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂單票號"])}
      },
      "en": {
        "title-ticket-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["票種"])},
        "title-ticket-expire-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["票卷當日有效"])},
        "title-ferry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["船班資訊"])},
        "title-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期"])},
        "title-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["航班時間"])},
        "title-passenger-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["乘客資訊"])},
        "title-passenger-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身分證字號"])},
        "title-passenger-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聯絡電話"])},
        "title-order-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂單票號"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"zh-TW":{"title-ticket-type":"票種","title-ticket-expire-notice":"票卷當日有效","title-ferry":"船班資訊","title-date":"日期","title-time":"航班時間","title-passenger-name":"乘客資訊","title-passenger-id":"身分證字號","title-passenger-phone":"聯絡電話","title-order-number":"訂單票號"},"en":{"title-ticket-type":"票種","title-ticket-expire-notice":"票卷當日有效","title-ferry":"船班資訊","title-date":"日期","title-time":"航班時間","title-passenger-name":"乘客資訊","title-passenger-id":"身分證字號","title-passenger-phone":"聯絡電話","title-order-number":"訂單票號"}}')
  delete Component.options._Ctor
  
}
