<template>
  <div>
      <div class="mx-4">
        <div class="my-3">
          <div class="d-flex align-content-start text-input-describe p-2">
            訂單編號<span class="notice-color">*</span>
          </div>
          <Input
            :value="inputs.orderNumber"
            :placeholder="'請輸入訂單編號'"
            :type="'text'"
            v-on:input="inputs.orderNumber = $event"
          />
          <div class="d-flex align-content-start p-2 pt-1 notice-color">
            <span v-if="notices.orderNumber_notice">{{notices.orderNumber_notice_msg}}</span>
          </div>
        </div>
        <div class="my-3">
          <div class="d-flex align-content-start text-input-describe p-2">
            訂票人電話號碼<span class="notice-color">*</span>
          </div>
          <Input
            :value="inputs.orderInfo"
            :placeholder="'請輸入訂票人電話號碼'"
            :type="'text'"
            v-on:input="inputs.orderInfo = $event"
          />
          <div class="d-flex align-content-start p-2 pt-1 notice-color">
            <span v-if="notices.orderID_notice">{{notices.orderID_notice_msg}}</span>
          </div>
        </div>
      </div>
      <div class="mb-4 mx-4">
        <div class="mt-4 mb-4 notice-color">
          <div>輸入訂票時所留的</div>
          <div><u class="fw-bold">訂票人電話號碼</u>進行查詢</div>
          <button class="btn-next my-1" v-on:click="btnClick()">
            下一步
          </button>
        </div>
        
        <div class="mt-4 mb-4 notice-color">
          <div>忘記您的票卷嗎?</div>
          <button class="btn-sub my-1" v-on:click="$emit('find-ticket')">
            找回票卷
          </button>
        </div>
        
      </div>
  </div>
</template>
<script>
import Input from "@/components/basic-components/input/custom-input.vue";
import { apiGetSearchOrder } from "@/apis/download.js";
import asyncLocalStorage from '@/utils/asyncLocalStorage.js';

export default {
  components: {
    Input,
  },
  data() {
    return {
      inputs: {
        orderNumber: "",
        orderInfo: "",
      },
      results: {},
      notices: {
        orderNumber_notice: false,
        orderNumber_notice_msg: "",
        orderID_notice: false,
        orderID_notice_msg: "",
      },
    };
  },
  methods: {
    init() {
      this.$store.commit("header/clearHeader");
      this.$store.commit("header/setHeader", {
        header: [
          { 
            title: "行動取票",
            type: "text",
          }
        ],
        type: "text"
      });
      this.getPathQuery();
    },
    btnClick() {
      if (this.inputs.orderNumber !== "" && this.inputs.orderInfo !== "") {
        this.sendSearchRequest();
      }
      if (this.inputs.orderNumber == "") {
        this.orderNumberNoticeChange( true, "請輸入訂單編號" );
      } 
      else {
        this.orderNumberNoticeChange( false, "" );
      }
      if (this.inputs.orderInfo == "") {
        this.orderIDNumNoticeChange( true, "請輸入訂票人資訊" );
      } 
      else {
        this.orderIDNumNoticeChange( false, "" );
      }
    },
    async sendSearchRequest() {
      this.$store.commit("modal/setLoadingState");
      let data = {
        order_number: this.inputs.orderNumber,
        info: this.inputs.orderInfo,
      };
      this.results = await apiGetSearchOrder(data);
      
      // else if ( this.results.status === 200
      //        && this.results.data.paid 
      //        && !this.checkFareArrayHasRemain( this.results.data.fares )
      //        && !this.isOrderRefunded(this.results.data) ) {
      //   this.orderNumberNoticeChange( true, "此筆訂單已取票" );

      //   this.$store.commit("modal/setModal", {
      //     active: true,
      //     status: "fail",
      //     message: [
      //       {
      //         type: "text",
      //         content: "此筆訂單已取票",
      //         fontWeight: "bold",
      //         color: "notice",
      //         mt: 3,
      //         mb: 2,
      //       },
      //       {
      //         type: "icon",
      //         content: "fail",
      //         fontWeight: "normal",
      //         color: "notice",
      //         mt: 2,
      //         mb: 3,
      //       },
      //     ],
      //   });

      // }
      if ( this.results.status === 200
             && this.results.data.paid 
             && !this.checkFareArrayHasRemain( this.results.data.fares )
             && this.isOrderCancel(this.results.data)) {
        this.orderNumberNoticeChange( true, "此筆訂單已取消" );

        this.$store.commit("modal/setModal", {
          active: true,
          status: "fail",
          message: [
            {
              type: "text",
              content: "此筆訂單已取消",
              fontWeight: "bold",
              color: "notice",
              mt: 3,
              mb: 2,
            },
            {
              type: "icon",
              content: "fail",
              fontWeight: "normal",
              color: "notice",
              mt: 2,
              mb: 3,
            },
          ],
        });
      }
      else if ( this.results.status === 200 && !this.results.data.paid ) {
        // this.orderNumberNoticeChange( true, "此筆訂單未付款，無法取票" );

        if ( !this.checkOrderExistLocalStorage(this.inputs.orderNumber) ) {
          await this.saveOrderInfoToLocalStorage(this.inputs.orderInfo, this.inputs.orderNumber);
        }

        this.$store.commit("modal/clearState");

        this.$router.push({ name: 'OrderDetail', params: { 
          order_number: this.inputs.orderNumber,
          info: this.inputs.orderInfo,
        }});

      }
      else if ( this.results.status === 200 
        && this.results.data.paid ) {
        console.log("get order data");

        if ( !this.checkOrderExistLocalStorage(this.inputs.orderNumber) ) {
          await this.saveOrderInfoToLocalStorage(this.inputs.orderInfo, this.inputs.orderNumber);
        }
        
        this.$store.commit("modal/clearState");

        this.$emit("search-result", {
          result: this.results.data.fares,
          info: this.inputs,
        });
      } 
      else {
        this.orderNumberNoticeChange( true, "查無此訂單" );

        this.$store.commit("modal/setModal", {
          active: true,
          status: "confirm",
          message: [
            {
              type: "text",
              content: "查無此訂單",
              fontWeight: "bold",
              color: "maincolor",
              mt: 2,
              mb: 2,
            },
            {
              type: "text-array",
              content: [
                {
                  bold: false,
                  underline: false,
                  text: "請確認",
                  color: "maincolor",
                },
                {
                  bold: true,
                  underline: true,
                  text: "訂單編號",
                  color: "maincolor",
                },
                {
                  bold: false,
                  underline: false,
                  text: "或",
                  color: "maincolor",
                },
                {
                  bold: true,
                  underline: true,
                  text: "行動電話",
                  color: "maincolor",
                }
              ],
              fontWeight: "normal",
              color: "maincolor",
              mt: 2,
              mb: 0,
            },
            {
              type: "text",
              content: "是否有誤",
              fontWeight: "normal",
              color: "maincolor",
              mt: 0,
              mb: 2,
            },
          ],
          options: [],
        });
        
        console.log("fail");
      }
    },
    checkFareArrayHasRemain( faresArray ) {
      for ( let i = 0 ; i < faresArray.length ; i++ ) {
        // console.log( faresArray[i].remain );
        if ( faresArray[i].remain > 0 ) {
          return true;
        }
      }
      return false;
    },
    orderNumberNoticeChange( _boolean, str ) {
      this.notices.orderNumber_notice = _boolean;
      this.notices.orderNumber_notice_msg = str;
    },
    orderIDNumNoticeChange( _boolean, str ) {
      this.notices.orderID_notice = _boolean;
      this.notices.orderID_notice_msg = str;
    },
    getLocalStorageParam() {
      // console.log(localStorage.getItem('o_n'),localStorage.getItem('o_i'));
      if ( localStorage.getItem('o_n') !== null && localStorage.getItem('o_i') !== null ) {
        this.inputs.orderNumber = localStorage.getItem('o_n');
        this.inputs.orderInfo = localStorage.getItem('o_i');
        localStorage.removeItem('o_n');
        localStorage.removeItem('o_i');
        this.sendSearchRequest();
      }
    },
    getPathQuery() {
      // console.log( 'o_n', this.$route.query.o_n, 'o_i', this.$route.query.o_i );
      if ( this.$route.query.o_n != undefined && this.$route.query.o_i ) {
        this.inputs.orderNumber = this.$route.query.o_n;
        this.inputs.orderInfo = this.$route.query.o_i;

        this.$route.query.o_n = undefined;
        this.$route.query.o_i = undefined;
        this.sendSearchRequest();
      }
    },
    checkOrderExistLocalStorage( orderNumber ) {
      let orderList = JSON.parse(localStorage.getItem('order_list'));
      if ( orderList == null ) {
        orderList = [];
      }
      for ( let i = 0 ; i < orderList.length ; i++ ) {
        if ( orderList[i].order_number === orderNumber ) {
          return true;
        }
      }
      return false;
    },
    async saveOrderInfoToLocalStorage( phone, order_number ) {

      let orderList = JSON.parse(localStorage.getItem('order_list'));
      if ( orderList == null ) {
        orderList = [];
      }
      let newOrder = {
        info: phone,
        order_number: order_number,
      };

      orderList.push( newOrder );

      localStorage.removeItem("order_list");
      await asyncLocalStorage.setItem("order_list", JSON.stringify(orderList)).then(function(){
        console.log('done, now localStorage orders:', JSON.parse(localStorage.getItem('order_list')));
      });
      // localStorage.setItem("order_list", JSON.stringify(orderList));

    },
    isOrderRefunded( orderData ) {
      for ( let i = 0 ; i < orderData.fares.length ; i++ ) {
        
        for ( let fare_index = 0 ; fare_index < orderData.fares[i].simple_ticket_infos.length ; fare_index++ ) {

          if ( orderData.fares[i].simple_ticket_infos[fare_index].out.status !== 'used'
            && orderData.fares[i].simple_ticket_infos[fare_index].out.status !== 'refunded' ) {
            return false ;
          }
          
          if ( orderData.fares[i].simple_ticket_infos[fare_index].in.status !== 'used'
            && orderData.fares[i].simple_ticket_infos[fare_index].in.status !== 'refunded' ) {
            return false ;
          }

        }

      }
      return true;
    },
    isOrderCancel( orderData ) {
      for ( let i = 0 ; i < orderData.fares.length ; i++ ) {
        
        for ( let fare_index = 0 ; fare_index < orderData.fares[i].simple_ticket_infos.length ; fare_index++ ) {

          if ( orderData.fares[i].simple_ticket_infos[fare_index].out.status !== 'refunded' ) {
            return false ;
          }
          
          if ( orderData.fares[i].simple_ticket_infos[fare_index].in.status !== 'refunded' ) {
            return false ;
          }

        }

      }
      return true;
    }
  },
  mounted() {
    this.init();
  }
};
</script>
