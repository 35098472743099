import Vue from 'vue';
import Vuex from 'vuex';
import ModuleButton from './modules/button.module.js';
import ModuleLang from './modules/lang.module.js';
import ModuleModal from './modules/modal.module.js';
import ModuleHeader from './modules/header.module.js';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        button: ModuleButton,
        lang: ModuleLang,
        modal: ModuleModal,
        header: ModuleHeader,
    },
  
})

export default store
  