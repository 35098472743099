<template>
  <div class="pb-4">
    <div v-if="!componentStatus.loading">
      <div v-for="(item, index) in componentData.orders" :key="index">
        <div v-if="componentStatus.showing_paid === item.data.paid">
          <OrderComponent
            :orderData="item.data"
            :orderNumber="item.order_number"
            :info="item.info"
          />
        </div>
      </div>
      <div 
         class="empty-message"
         v-if="(!componentStatus.showing_paid && !hasNonPaidOrder)
             ||(componentStatus.showing_paid && !hasPaidOrder)">
        您尚未訂購任何票卷
      </div>
    </div>
  </div>
</template>
<script>
import OrderComponent from "./components/order/order.vue";
import { apiGetSearchOrder } from "@/apis/download.js";

export default {
  components: {
    OrderComponent,
  },
  data() {
    return {
      componentStatus: {
        showing_paid: false,
      },
      componentData: {
        orders: [],
      },
    };
  },
  watch: {
    '$route.query.status': function() {
      if ( this.$route.query.status === "non-paid" ) {
        this.componentStatus.showing_paid = false;
      }
      else if ( this.$route.query.status === "paid" ) {
        this.componentStatus.showing_paid = true;
      }
    },
  },
  computed: {
    hasNonPaidOrder() {
      for ( let i = 0 ; i < this.componentData.orders.length ; i++ ) {
        if ( !this.componentData.orders[i].data.paid ) 
          return true ;
      }
      return false;
    },
    hasPaidOrder() {
      for ( let i = 0 ; i < this.componentData.orders.length ; i++ ) {
        if ( this.componentData.orders[i].data.paid ) 
          return true ;
      }
      return false;
    },
  },
  methods: {
    async init() {
      this.$store.commit("header/setHeader", {
        header: [
          {
            title: "查詢訂單",
            type: "text"
          }
        ],
        type: "text"
      });
      
      await this.loadAllOrderInLocal();
      
      this.$store.commit("header/setHeader", {
        header: [
          { 
            title: "未付款" + "(" + this.getNotPaidNum() + ")",
            type: "link",
            href: "?status=non-paid",
          },
          { 
            title: "已付款" + "(" + this.getPaidNum() + ")",
            type: "link",
            href: "?status=paid",
          }
        ],
        type: "link"
      });
      if (this.$route.query.status==="paid") {
        this.componentStatus.showing_paid = true;
      }
      else {
        this.$router.push('?status=non-paid');
        this.componentStatus.showing_paid = false;
      }

    },
    async loadAllOrderInLocal() {
      this.$store.commit("modal/setLoadingState");
      let orderList = JSON.parse(localStorage.getItem("order_list"));
      for (let i = 0; orderList !== null && i < orderList.length; i++) {
        let sendData = {
          order_number: orderList[i].order_number,
          info: orderList[i].info,
        };
        let result = await apiGetSearchOrder(sendData);
        // console.log(result);
        if (result.status === 200) {
          this.componentData.orders.push({
            data: result.data,
            order_number: orderList[i].order_number,
            info: orderList[i].info,
          });
        } else {
          console.log(result);
        }
      }
      this.$store.commit("modal/clearState");
    },
    getPaidNum() {
      let num = 0;
      for (let i = 0; i < this.componentData.orders.length; i++) {
        if (this.componentData.orders[i].data.paid === true) num++;
      }
      return num;
    },
    getNotPaidNum() {
      let num = 0;
      for (let i = 0; i < this.componentData.orders.length; i++) {
        if (this.componentData.orders[i].data.paid === false) num++;
      }
      return num;
    },
  },
  mounted() {
    this.init();
  },
};
</script>
