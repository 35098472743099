<template>
    <input class="custom-input" :type='type' :placeholder='placeholder'
     v-bind:value="value"
     v-on:input="$emit('input', $event.target.value)"
     v-bind:max='max'
     v-bind:min='min'
    >  
</template>
<script>
export default {
    props: {
        value: String,
        placeholder: String,
        type: String,
        max: String,
        min: String,
    },
    data() {
        return {
            
        }
    },
    computed: {
        
    },
    methods: {
        
    },
    
}
</script>
<style scoped>
@import './style/input.css';
</style>