<template>
  <div>
      <div class="my-3">
        <div class="mx-4 text-start">
          <div>
            <div class="d-flex justify-content-between my-1">
              <span class="text-input-describe">訂票編號</span>
              <span class="d-flex justify-content-end">
                <div class="btn-fare bg-mc h-25p">
                  來回票
                </div>
              </span>
            </div>
            <div class="d-flex justify-content-between my-2">
              <span class="text-dark fw-bold">{{order.order_number}}</span>
              <CopyBtn :input="order.order_number"/>
            </div>
          </div>

          <hr />

          <div>
            <div class="d-flex row">
              <span class="text-input-describe">去程日期</span>
            </div>
            <div class="text-dark fw-bold">
              {{order.data.schedule.DL.date}} 
              {{getWeekday(order.data.schedule.DL.date)}}
              {{order.data.schedule.DL.time}}
            </div>
            <div class="d-flex route-info align-items-center">
              <span class="from fw-bold">東港</span>
              <span class="d-flex align-items-center mx-4 text-dark fs-2em">
                &rarr;
              </span>
              <span class="to fw-bold">小琉球</span>
            </div>
          </div>

          <hr />

          <div>
            <div class="d-flex row">
              <span class="text-input-describe">回程日期</span>
            </div>
            <div class="text-dark fw-bold">
              {{order.data.schedule.LD.date}} 
              {{getWeekday(order.data.schedule.LD.date)}}
              {{order.data.schedule.LD.time}} 
            </div>
            <div class="d-flex route-info align-items-center">
              <span class="from fw-bold">小琉球</span>
              <span class="d-flex align-items-center mx-4 text-dark fs-2em">
                &rarr;
              </span>
              <span class="to fw-bold">東港</span>
            </div>
          </div>


        </div>

        <div class="my-2">
            <div class="table-top-bar d-flex align-items-center">
                <div class="mx-4 text-dec d-flex justify-content-between w-100">
                    <div>票種</div>
                    <div>單價</div>
                    <div>數量</div>
                    <div>小計</div>
                </div>
            </div>
            
            <div 
              class="d-flex align-items-center text-black-50"
              v-if="componentStatus.tickets.F.isExist"
            >
                <div class="mx-4 text-dec d-flex justify-content-between w-100 py-4">
                    <div>全票</div>
                    <div>NT${{numberWithCommas(componentStatus.tickets.F.price)}}</div>
                    <div>{{numberWithCommas(componentStatus.tickets.F.quantity)}}</div>
                    <div>NT${{numberWithCommas(componentStatus.tickets.F.total)}}</div>
                </div>
            </div>
            <div 
              class="d-flex align-items-center text-black-50"
              v-if="componentStatus.tickets.H.isExist"
            >
                <div class="mx-4 text-dec d-flex justify-content-between w-100 py-4">
                    <div>半票</div>
                    <div>NT${{numberWithCommas(componentStatus.tickets.H.price)}}</div>
                    <div>{{numberWithCommas(componentStatus.tickets.H.quantity)}}</div>
                    <div>NT${{numberWithCommas(componentStatus.tickets.H.total)}}</div>
                </div>
            </div>
        </div>

        <hr class="mx-4" />

        <div class="mx-4 text-start my-2">
             <div class="d-flex my-2 justify-content-between align-items-end">
                <div class="text-dark text-start">總票價 新台幣</div>
                <div class="text-end notice-color fw-bold fs-1p4em">
                    NT${{numberWithCommas(
                        componentStatus.tickets.F.total + componentStatus.tickets.H.total
                    )}}
                </div>
            </div>
            <div class="d-flex my-2 justify-content-between align-items-end">
                <div class="text-dark text-start">折扣碼抵用金額</div>
                <div class="text-end notice-color  fs-1p4em">
                    -NT${{numberWithCommas(
                        componentStatus.tickets.F.discount + componentStatus.tickets.H.discount
                    )}}
                </div>
            </div>
            <hr />
                   <div class="d-flex my-2 justify-content-between align-items-end">
                <div class="text-dark text-start fw-bold fs-1p6em">本單需付款金額</div>
                <div class="text-end notice-color fw-bold fs-1p6em">
                    NT${{numberWithCommas(
                        (componentStatus.tickets.F.total-componentStatus.tickets.F.discount) + (componentStatus.tickets.H.total -componentStatus.tickets.H.discount)
                    )}}
                </div>
            </div>
            <div class="notice-field my-4">
              <div class="notice-color fw-bold text-start m-4 d-flex">
                <div>
                  ※
                </div>
                <div>請螢幕截圖此頁或記下訂單編號，以利查詢取票。</div>
              </div>
              <div class="notice-color fw-bold text-start m-4 d-flex">
                <div>
                  ※
                </div>
                <div>開船前30分鐘完成取票登船，逾時視為侯補等侯下一班船。</div> 
              </div>
            </div>
            

            <div v-if="!order.data.paid">
                <div class="d-flex justify-content-between my-2">
                  <button class="btn-pay cancel" v-on:click="clickCancelOrder()">
                    取消訂單
                  </button>
                  <button class="btn-pay notnow" v-on:click="$router.push({path:'/order'})">
                    稍後付款
                  </button>
                </div>
                
                <button class="btn-next" v-on:click="payNow()">
                  現在付款
                </button>
            </div>
            <div class="d-flex justify-content-center" v-if="order.data.paid">
                <button class="btn-pay notnow" v-on:click="$router.push({path:'/order'})">
                  回查詢訂單
                </button>
            </div>

        </div>

      </div>
  </div>
</template>
<script>
import { apiGetFares } from "@/apis/booking.js";
import { apiGetSearchOrder } from "@/apis/download.js";
import CopyBtn from '@/components/basic-components/btn-copy/btn-copy.vue';
import { apiPostCancelOrder } from "@/apis/refund.js";
import { mapGetters } from 'vuex'
import asyncLocalStorage from '@/utils/asyncLocalStorage.js';

export default {
  components: {
    CopyBtn
  },
  data() {
    return {
      order: {},
      detailLoadComplete: false,
      componentStatus: {
        //   fares: {},
          tickets: {
              H:{
                  isExist: false,
                  price: 0,
                  total: 0,
                  quantity: 0,
                  discount:0,
              },
              F:{
                  isExist: false,
                  price: 0,
                  total: 0,
                  quantity: 0,
                  discount:0,
              },
          },
      },
    };
  },
  computed: {
        ...mapGetters({
            modalConfirmState: 'modal/getModalConfirm',
        })
  },
  watch: {
    'modalConfirmState': async function() {
      if ( this.$store.getters["modal/getModalConfirm"] && this.$store.getters["modal/getModalTarget"]==='order-cancel' ) {
        this.cancelOrder( this.order.order_number, this.order.info );
      }
    }
  },
  methods: {
    submit() {},
    async init() {
        this.$store.commit("header/clearHeader");
        this.$store.commit("header/setHeader", {
          header: [
            { 
              title: "訂單明細",
              type: "text",
            }
          ],
          type: "text"
        });
        this.$store.commit("modal/setLoadingState");
        await this.getOrderData();
        this.computePrice();
        this.detectPayRedirectBack();
        this.detailLoadComplete = true;
    },
    async getOrderData() {
      let data = {
        order_number: this.$route.params.order_number,
        info: this.$route.params.info,
      }
      let result = await apiGetSearchOrder(data);
      if ( result.status === 200 ) {
        console.log('success',result);
        this.order = {
          data: result.data,
          order_number: this.$route.params.order_number,
          info: this.$route.params.info,
        };
        this.$store.commit("modal/clearState");
      }
      else {
        console.error('fail',result);
        this.$router.push({path:'/order'});
      }
    },
    computePrice() {
      for ( let i = 0 ; i < this.order.data.fares.length ; i++ ) {
        if ( this.order.data.fares[i].type==='adult' ) {
          this.componentStatus.tickets.F.isExist = true;
          this.componentStatus.tickets.F.quantity = this.order.data.fares[i].count;
          this.componentStatus.tickets.F.price = this.order.data.fares[i].price;
          this.componentStatus.tickets.F.total = this.componentStatus.tickets.F.quantity*this.componentStatus.tickets.F.price; 
          this.componentStatus.tickets.F.discount = this.componentStatus.tickets.F.quantity*this.order.data.fares[i].coupon_discount;
        }
        if ( this.order.data.fares[i].type==='children' ) {
          this.componentStatus.tickets.H.isExist = true;
          this.componentStatus.tickets.H.quantity = this.order.data.fares[i].count;
          this.componentStatus.tickets.H.price = this.order.data.fares[i].price;
          this.componentStatus.tickets.H.total = this.componentStatus.tickets.H.quantity*this.componentStatus.tickets.H.price; 
          this.componentStatus.tickets.H.discount = this.componentStatus.tickets.H.quantity* this.order.data.fares[i].coupon_discount;
        }
      }
    },
    async getFares() {
        
        let faresResult = await apiGetFares({
            bee_uuid: localStorage.getItem('bee_uuid')
        });
        if ( faresResult.status === 200 ) {
          console.log(`aresResult.data`,faresResult.data)
            this.componentStatus.fares = faresResult.data;
        }
        else {
            console.log(faresResult);
        }
    },
    clickBack() {
        this.$emit('back','work');
    },
    numberWithCommas(num) {
        if ( this.isNotUndefined(num) )
            return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        else 
            return "";
    },
    isNotUndefined( val ) {
        // console.log( val, typeof val === 'undefined' )
        if ( typeof val === 'undefined' ) {
            return false;
        }
        else {
            return true ;
        }
    },
    clickCancelOrder() {
      this.$store.commit("modal/setModal", {
        active: true,
        status: "select",
        message: [
          {
            type: "text",
            content: "確定要取消此筆訂單？",
            fontWeight: "bold",
            color: "maincolor",
            mt: 3,
            mb: 3,
          },
          {
            type: "text",
            content: "取消後則無法恢復此訂單，\n需要重新進行訂票作業。",
            fontWeight: "normal",
            color: "maincolor",
            mt: 2,
            mb: 3,
          },
        ],
        target: "order-cancel",
        options: [],
      });

    },
    async cancelOrder( orderNumber, orderInfo ) {
      this.$store.commit("modal/setLoadingState");
      let data = {
        order_number: orderNumber,
        phone: orderInfo
      };
      let cancelResult = await apiPostCancelOrder({
        orders: [data],
      });
      console.log(cancelResult);
      if ( cancelResult.status === 200 ) {

        let orderList = JSON.parse(localStorage.getItem('order_list'));
        for ( let i = 0 ; i < orderList.length ; i++ ) {
          if ( orderList[i].order_number === orderNumber ) {
            orderList.splice(i, 1);
          }
        }
        localStorage.removeItem("order_list");
        await asyncLocalStorage.setItem("order_list", JSON.stringify(orderList)).then(function(){
          console.log('done, now localStorage orders:', JSON.parse(localStorage.getItem('order_list')));
        });

        this.$store.commit("modal/setModal", {
            active: true,
            status: "success",
            message: [
              {
                type: "text",
                content: this.execCancelOrderResult(cancelResult.data),
                fontWeight: "bold",
                color: "maincolor",
                mt: 3,
                mb: 2,
              },
              {
                type: "icon",
                content: "success",
                fontWeight: "normal",
                color: "",
                mt: 2,
                mb: 3,
              },

            ],
        });

        let thisVue = this;
        setTimeout( function(){ 
          thisVue.$store.commit("modal/clearState");
          thisVue.$router.push({
            name: 'Order',
          }) ;
        }, 3000 );
      }
      else {
        this.$store.commit("modal/setModal", {
            active: true,
            status: "fail",
            message: [
              {
                type: "text",
                content: cancelResult.message,
                fontWeight: "bold",
                color: "notice",
                mt: 3,
                mb: 2,
              },
              {
                type: "icon",
                content: "fail",
                fontWeight: "normal",
                color: "notice",
                mt: 2,
                mb: 3,
              },
            ],
        });
      }
      this.$store.commit("modal/restoreConfirmState");
    },
    execCancelOrderResult( cancelResultArray ) {
      let message = "訂單\n";
      for ( let i = 0 ; i < cancelResultArray.length ; i++ ) {
        message += cancelResultArray[i].order_number + "取消"
               + ( cancelResultArray[i].success ? "成功":"失敗" ) + "\n";
      }
      return message;
    },
    payNow() {
      this.$emit( 'pay-order', {
        redirect_back: false,
        paid: this.order.data.paid,
        order_number: this.order.order_number,
        info: this.order.info,
        price: (this.componentStatus.tickets.F.total + this.componentStatus.tickets.H.total),
      });
    },
    detectPayRedirectBack() {
      if ( this.$route.params.order_number !== undefined && 
           this.$route.params.info != undefined && 
           this.$route.query.redirect_back ) {
        console.log('pay-redirect');
        this.$emit( 'pay-redirect', {
          redirect_back: true,
          paid: this.order.data.paid,
          order_number: this.order.order_number,
          info: this.order.info,
          price: (this.componentStatus.tickets.F.total + this.componentStatus.tickets.H.total),
        });
      }
    },
    copyToClipBoard(number) {
      navigator.clipboard.writeText(number)
      .then(
        () => {
            console.log("Text copied to clipboard...")
        }
      ).catch(
        err => {
            console.log('Something went wrong', err);
      });
    },
  },
  async mounted() {
    await this.init();
  },
};
</script>
<style scope>
@import './style/orderDetail.css';
</style>
