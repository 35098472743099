import request from '@/utils/request.js';

export const apiPostCancelOrder =
    data => request("post",'/api/v1/cancel-order',data)
            .then(function(res){
                return res.data ;
            });

export const apiPostRefundTickets =
    data => request("post",'/api/v1/ticket/refund',data)
            .then(function(res){
                return res.data ;
            });