import Vue from 'vue'
import App from './App.vue'
import router from './router/router.js'
import store from './store/store.js'
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import VueSplide from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import DateMixin from './mixin/DateMixin.js'
import InputValidateMixin from './mixin/InputValidateMixin.js'
import EnvVariableMixin from './mixin/EnvVariableMixin.js'
import I18nMixin from './mixin/I18nMixin.js'
import MaskImageObject from './mixin/MaskImageObject.js'


import 'vue-smooth-picker/dist/css/style.css'
import SmoothPicker from 'vue-smooth-picker'

// css
import '@/styles/base.css';
import '@/styles/main.css';
import '@/styles/smooth-picker.css';

import getEnv from './utils/env.js'
import i18n from '@/utils/i18n.js'

Vue.use(SmoothPicker)
Vue.use( VueSplide );

Vue.config.productionTip = false
Vue.mixin(DateMixin)
Vue.mixin(InputValidateMixin)
Vue.mixin(EnvVariableMixin)
Vue.mixin(I18nMixin)
Vue.mixin(MaskImageObject)

Sentry.init({
  Vue,
  dsn: getEnv('VUE_APP_SENTRY_DSN'),
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [ getEnv('VUE_APP_APP_URL'), /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
