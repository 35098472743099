<template>
  <div>
      <div class="mx-4">
        <div class="my-3">
          <div class="d-flex align-content-start text-input-describe p-2">
            訂單編號<span class="notice-color">*</span>
          </div>
          <Input
            :value="inputs.orderNumber"
            :placeholder="'請輸入訂單編號'"
            :type="'text'"
            v-on:input="inputs.orderNumber = $event"
          />
          <div class="d-flex align-content-start p-2 pt-1 notice-color">
            <span v-if="notices.orderNumber_notice">{{notices.orderNumber_notice_msg}}</span>
          </div>
        </div>
        <div class="my-3">
          <div class="d-flex align-content-start text-input-describe p-2">
            取票人資訊<span class="notice-color">*</span>
          </div>
          <Input
            :value="inputs.takerID4Number"
            :placeholder="'請輸入取票人身分證後四碼'"
            :type="'text'"
            v-on:input="inputs.takerID4Number = $event"
          />
          <div class="d-flex align-content-start p-2 pt-1 notice-color">
            <span v-if="notices.takerID_notice">{{notices.takerID_notice_msg}}</span>
          </div>
        </div>
        <div class="my-2 notice-color">
          <div>請輸入<u class="fw-bold">乘客身分證末四碼</u>，</div>
          <div>如果未取得任何票卷，請至<span class="fw-bold">「行動取票」</span></div>
          <div>取得您的票卷</div>
        </div>
      </div>
      <div class="mb-4 mx-4">
        <button class="btn-next my-2" v-on:click="btnClick()">
          下一步
        </button>
        <button class="btn-sub my-2" v-on:click="$emit('download-ticket')">
          返回取票
        </button>
      </div>
  </div>
</template>
<script>
import Input from "@/components/basic-components/input/custom-input.vue";
import { apiPostTakeBackTicket } from "@/apis/download.js";
import asyncLocalStorage from '@/utils/asyncLocalStorage.js';

export default {
  components: {
    Input,
  },
  data() {
    return {
      inputs: {
        orderNumber: "",
        takerID4Number: "",
      },
      results: {},
      notices: {
        orderNumber_notice: false,
        orderNumber_notice_msg: "",
        takerID_notice: false,
        takerID_notice_msg: "",
      },

    };
  },
  methods: {
    btnClick() {
      if (this.inputs.orderNumber !== "" && this.inputs.takerID4Number !== "") {
        this.sendSearchRequest();
      }
      if (this.inputs.orderNumber == "") {
        this.orderNumberNoticeChange( true, "請輸入訂單編號" );
      } else {
        this.orderNumberNoticeChange( false, "" );
      }
      if (this.inputs.takerID4Number == "") {
        this.orderIDNumNoticeChange( true, "請輸入取票人資訊" );
      } else {
        this.orderIDNumNoticeChange( false, "" );
      }
    },
    async sendSearchRequest() {
      this.$store.commit("modal/setLoadingState");
      let data = {
        order_number: this.inputs.orderNumber,
        info: this.inputs.takerID4Number,
      };
      this.results = await apiPostTakeBackTicket(data);
      if (this.results.status === 200 && this.results.data.length > 0 ) {
        this.$store.commit("modal/clearState");
        console.log("get-back ticket data");
        await this.setTicketLocalStorage(this.results.data);
        this.$router.push({ path: 'my-tickets' });
      } 
      else {
        this.orderNumberNoticeChange( true, "查無此訂單" );
        this.$store.commit("modal/setModal", {
          active: true,
          status: "fail",
          message: [
            {
              type: "text",
              content: "查無此訂單",
              fontWeight: "bold",
              color: "notice",
              mt: 3,
              mb: 2,
            },
            {
              type: "icon",
              content: "fail",
              fontWeight: "normal",
              color: "notice",
              mt: 2,
              mb: 3,
            },
          ],
        });
        console.log("fail");
      }
    },
    async setTicketLocalStorage( resultData ) {
        // get localStorage tickets, then push download tickets ;
        let myTickets = JSON.parse(localStorage.getItem('tickets'));
        if ( myTickets == null ) 
            myTickets = [];
        for ( let i = 0 ; i < resultData.length ; i++ ) {
          if ( !this.checkTicketExist(resultData[i].number) ) {
            myTickets.push({
                data: resultData[i],
                isUsed: false,
            });
          }
        }
        await asyncLocalStorage.setItem("tickets", JSON.stringify(myTickets)).then(function(){
          console.log('done, now localStorage tickets:', JSON.parse(localStorage.getItem('tickets')));
        });
    },
    checkTicketExist( ticketNumber ) {
      let myTickets = JSON.parse(localStorage.getItem('tickets'));
      if ( myTickets == null ) 
        myTickets = [];

      for ( let i = 0 ; i < myTickets.length ; i++ ) {
        if ( myTickets[i].data.number === ticketNumber ) {
          return true;
        }
      }
      return false;
      
    },
    orderNumberNoticeChange( _boolean, str ) {
      this.notices.orderNumber_notice = _boolean;
      this.notices.orderNumber_notice_msg = str;
    },
    orderIDNumNoticeChange( _boolean, str ) {
      this.notices.takerID_notice = _boolean;
      this.notices.takerID_notice_msg = str;
    }
  },
  mounted() {
    this.$store.commit("header/clearHeader");
    this.$store.commit("header/setHeader", {
      header: [
        { 
          title: "找回票卷",
          type: "text",
        }
      ],
      type: "text"
    });
  }
};
</script>
