<template>
    <div class="header-text-px py-4 text-title-color text-start fw-normal d-flex align-items-center">
        <div class="header-text-mx">
            <div class="d-flex text-nowrap">
                訂單編號
                <div class="notice-color fw-bold mx-1"> {{orderNumber}} </div>
                ，一共有
                <div class="number-block mx-1">{{ticketCount}}</div>
                張票卷
            </div>
            <div class="d-flex text-nowrap my-1">
                已取
                <div class="number-block mx-1">{{numTicketTaken}}</div>
                張船票，取票時需填寫個人資料。
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        orderNumber: String,
        ticketCount: Number,
        numTicketTaken: Number,
    }
}
</script>
<style scoped>
@import './style/orderInfoRow.css';
</style>