<template>
  <div>
    <Header />
    <div v-if="pageStatus.step === 1"> 
      <BookingSearch 
        v-bind:bee_name="pageStatus.bee_name"
        @search-data="catchSearchData"
      />
    </div>
    <div v-if="pageStatus.step === 2">
      <ShippingSelect
        v-bind:_departureDate="dateStringToYMDString(pageStatus.searchData.departureDate)"
        v-bind:_returnDate="dateStringToYMDString(pageStatus.searchData.returnDate)"
        v-bind:_route="pageStatus.searchData.routeCode"
        v-bind:_from="pageStatus.searchData.fromStation"
        v-bind:_to="pageStatus.searchData.toStation"
        :_ticketCount="pageStatus.searchData.ticketFCount+pageStatus.searchData.ticketHCount"
        @select-shiptime="catchSelectData"
        @back="pageStatus.step=1"
      />
    </div> 
    <div v-if="pageStatus.step === 3">
      <FillInOrderInfo
        @info-data="catchOrderInfoData"
        @back="pageStatus.step=2"
        :searchData="pageStatus.searchData"
      />
    </div>

  </div>
  

</template>
<script>
import Header from '@/components/layout-components/header/header.vue'
import BookingSearch from '@/components/pages/bookingPage/bookingSearch/bookingSearch.vue';
import ShippingSelect from '@/components/pages/bookingPage/shippingSelect/shippingSelect.vue';
import FillInOrderInfo from '@/components/pages/bookingPage/fillInOrderInfo/fillInOrderInfo.vue';
import { apiPostBookingTickets } from '@/apis/booking.js';
import { apiGetSearchOrder } from "@/apis/download.js";
import asyncLocalStorage from '@/utils/asyncLocalStorage.js';

export default {
  components: {
    Header,
    BookingSearch,
    ShippingSelect,
    FillInOrderInfo,
  },
  data() {
    return {
      pageStatus: {
        step: 1,
        searchData: {},
        selectData: {},
        orderInfoData: {},
        bookResult: {},
        bee_uuid: "",
        bee_name: null,
      },
      
    }
  },
  watch: {
    'pageStatus.step': function( val ) {
      if ( val === 1 ) {
        this.pageStatus.searchData = {};
        this.pageStatus.bookResult = {};
      }
      else if ( val === 2 ) {
        this.pageStatus.selectData = {};
      }
      else if ( val === 3 ) {
        this.pageStatus.orderInfoData = {};
      }
      else if ( val === 4 ) {
        this.pageStatus.searchData = {};
        this.pageStatus.selectData = {};
        this.pageStatus.orderInfoData = {};
      }
      
    }
  },
  methods: {
    catchSearchData( data ) {
      this.pageStatus.searchData = data;
      this.pageStatus.step = 2;
    },
    catchSelectData( data ) {
      this.pageStatus.selectData = data;
      this.pageStatus.step = 3;
    },
    async catchOrderInfoData( data ) {
      this.pageStatus.orderInfoData = data;

      if ( await this.sendBookingRequest() ) {
        // 2021/08/04 調整利用router.push將頁面重導向，以查訂單來顯示訂單內容，來統一api收到的欄位格式 ;
        
        this.$router.push({
          path: '/order/' + this.pageStatus.bookResult.order_number + '/' + this.pageStatus.orderInfoData.phoneNumber
        });
        // this.pageStatus.step = 4;
      }
      else {
        this.pageStatus.step = 1;
        console.log('booking error'); 
      }
    },
    async sendBookingRequest() {
      this.$store.commit("modal/setLoadingState");
      let data = {
        bee_uuid: this.pageStatus.bee_uuid,
        route_code: this.pageStatus.searchData.routeCode,
        departure_date: (this.pageStatus.selectData.departure.date + ' ' + this.pageStatus.selectData.departure.time),
        return_date: (this.pageStatus.selectData.return.date + ' ' + this.pageStatus.selectData.return.time),
        // name: this.pageStatus.orderInfoData.name,
        id_number: this.pageStatus.orderInfoData.idcardNumber,
        phone: this.pageStatus.orderInfoData.phoneNumber,
        adult: this.pageStatus.searchData.ticketFCount,
        children: this.pageStatus.searchData.ticketHCount,
        is_passport: this.pageStatus.orderInfoData.is_passport,
        coupon_code:this.pageStatus.orderInfoData.couponCode
      };

      this.pageStatus.bee_uuid = ""; // 訂完一次票就清除小蜜蜂uuid ;
      this.pageStatus.bee_name = null;
      let orderResult = await apiPostBookingTickets(data);
      if ( orderResult.status === 200 ) {
        this.pageStatus.bookResult = orderResult.data;
        this.$store.commit("modal/clearState");
        
        await this.saveOrderInfoToLocalStorage( 
          this.pageStatus.orderInfoData.phoneNumber,
          orderResult.data.order_number
        );

        return true ;
      }
      else {
        console.log(orderResult);
        this.$store.commit("modal/setModal", {
          active: true,
          status: "fail",
          message: [
            {
              type: "text",
              content: "發生錯誤\n"+orderResult.message,
              fontWeight: "bold",
              color: "notice",
              mt: 2,
              mb: 2,
            },
            {
              type: "icon",
              content: "fail",
              fontWeight: "normal",
              color: "notice",
              mt: 1,
              mb: 2,
            },
          ],
        });
        return false ;
      }
    },
    init() {
      this.$store.commit('button/changeButtonActive',{
        id: 1,
      });
      this.getBeeUUID();
      this.clearLocalStorageBeeUUID();

      this.getRedirectBookingData();
    },
    getRedirectBookingData() {
      if ( this.$route.query.book ) {
        console.log(this.$route.query.dd);

        let ddObject = this.dateToYMDObject(this.$route.query.dd);
        let rdObject = this.dateToYMDObject(this.$route.query.rd);
        this.pageStatus.searchData = {
          departureDate: this.generateDateString(ddObject.y,ddObject.m,ddObject.d),
          returnDate: this.generateDateString(rdObject.y,rdObject.m,rdObject.d),
          ticketFCount: parseInt(this.$route.query.at),
          ticketHCount: parseInt(this.$route.query.ct),
          routeCode: "DL",
          ticketType: "來回",
          fromStation: "東港",
          toStation: "小琉球"
        }
        this.pageStatus.step = 2;
      }
    },
    getBeeUUID() {
      let uuid = localStorage.getItem("bee_uuid");
      let name = localStorage.getItem("bee_name");
      this.pageStatus.bee_uuid = uuid;
      this.pageStatus.bee_name = name;
    },
    clearLocalStorageBeeUUID() {
      localStorage.removeItem("bee_uuid");
      localStorage.removeItem("bee_name");
    },
    async saveOrderInfoToLocalStorage( phone, order_number ) {

      let orderList = JSON.parse(localStorage.getItem('order_list'));
      if ( orderList == null ) {
        orderList = [];
      }
      let newOrder = {
        info: phone,
        order_number: order_number,
      };

      orderList.push( newOrder );

      localStorage.removeItem("order_list");
      await asyncLocalStorage.setItem("order_list", JSON.stringify(orderList)).then(function(){
        console.log('done, now localStorage orders:', JSON.parse(localStorage.getItem('order_list')));
      });
      // localStorage.setItem("order_list", JSON.stringify(orderList));

    },
    async getBookingInfo() {
      if ( this.$route.query.order_number != null
        && this.$route.query.info != null ) {
        let result = await apiGetSearchOrder({
          order_number: this.$route.query.order_number,
          info: this.$route.query.info,
        });
        if ( result.status === 200 ) {
          
          this.execTicketType(result);

          this.pageStatus.bookResult = {
            schedule: [ result.data.schedule.DL, result.data.schedule.LD ],
            status: result.data.paid ? 'paid':'non_payment',
            order_number: this.$route.query.order_number,
            ticket: this.execTicketType(result),
          };
          this.pageStatus.step = 4;

        }
        else {
          console.log('cannot find this order.');
        }
      }
    },
    execTicketType( result ) {
      let hasA = false;
      let hasC = false;
      let adultT = {};
      for ( let i = 0 ; i < result.data.fares.length ; i++ ) {
        if ( result.data.fares[i].type === 'adult' ) {
          adultT = {
            price: result.data.fares[i].price,
            quantity: result.data.fares[i].count,
          }
          hasA = true;
        }
      }
      let childT = {};
      for ( let i = 0 ; i < result.data.fares.length ; i++ ) {
        if ( result.data.fares[i].type === 'children' ) {
          childT = {
            quantity: result.data.fares[i].count,
            price: result.data.fares[i].price,
          }
          hasC = true;
        }
      }
      if ( hasA && hasC ) {
        return {
          adult: adultT,
          children: childT,
        }
      }
      else if ( hasA && !hasC ) {
        return {
          adult: adultT,
        }
      }
      else if ( !hasA && hasC ) {
        return {
          children: childT,
        }
      }
      return null;
    }

  },
  mounted(){
    this.init();
  },
}
</script>