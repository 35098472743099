module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "status-picked-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已取"])},
        "status-used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已使用"])},
        "passenger-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["乘客資訊"])},
        "passenger-info-title-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["乘客姓名"])},
        "passenger-info-title-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身分證字號(護照號碼)"])},
        "passenger-info-title-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手機號碼"])},
        "passenger-info-title-fare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["票種"])}
      },
      "zh-TW": {
        "status-picked-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已取"])},
        "status-used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已使用"])},
        "passenger-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["乘客資訊"])},
        "passenger-info-title-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["乘客姓名"])},
        "passenger-info-title-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身分證字號(護照號碼)"])},
        "passenger-info-title-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手機號碼"])},
        "passenger-info-title-fare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["票種"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"status-picked-up":"已取","status-used":"已使用","passenger-info":"乘客資訊","passenger-info-title-name":"乘客姓名","passenger-info-title-id":"身分證字號(護照號碼)","passenger-info-title-phone":"手機號碼","passenger-info-title-fare":"票種"},"zh-TW":{"status-picked-up":"已取","status-used":"已使用","passenger-info":"乘客資訊","passenger-info-title-name":"乘客姓名","passenger-info-title-id":"身分證字號(護照號碼)","passenger-info-title-phone":"手機號碼","passenger-info-title-fare":"票種"}}')
  delete Component.options._Ctor
  
}
