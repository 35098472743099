<template>
  <div>
    <div class="top-bar d-flex align-items-center">
      <div class="w-100 d-flex align-items-center px-2">
        <div class="col-3 d-flex justify-content-center">
          <div class="d-flex button mx-1">
            <div class="arrow_lr left"></div>
            <div class="mx-3 text-nowrap" v-on:click="clickBack()">返回</div>
          </div>
        </div>   
      </div>
    </div>

      <div class="mx-4">
        <div class="my-3">
          <div class="d-flex align-content-start text-input-describe p-2 justify-content-between">
            <div>
              <span v-if="!inputs.passenger.is_passport">訂票人身分證字號</span>
              <span v-if="inputs.passenger.is_passport">訂票人護照編號</span>
              <span class="notice-color">*(必填)</span>
            </div>
            <div class="d-flex align-items-center">
              <input class="mx-1" type="checkbox" v-model="inputs.passenger.is_passport">護照編號
            </div>
          </div>
          <Input
            :value="inputs.passenger.idcardNumber"
            :placeholder="'請輸入身分證字號/護照號碼'"
            :type="'text'"
            v-on:input="inputs.passenger.idcardNumber = $event"
          />
          <div class="d-flex align-content-start p-2 pt-1 notice-color">
            <span v-if="notices.notice_id">{{notices.notice_id_msg}}</span>
          </div>
        </div>
        <div class="my-3">
          <div class="d-flex align-content-start text-input-describe p-2">
            手機號碼<span class="notice-color">*(必填)</span>
          </div>
          <Input
            :value="inputs.passenger.phoneNumber"
            :placeholder="'請輸入手機號碼'"
            :type="'text'"
            v-on:input="inputs.passenger.phoneNumber = $event"
          />
          <div class="d-flex align-content-start p-2 pt-1 notice-color">
            <span v-if="notices.notice_phone">{{notices.notice_phone_msg}}</span>
          </div>
        </div>
        <div class="my-3">
          <div class="d-flex align-content-start text-input-describe p-2">
            輸入折扣碼<span class="notice-color">*(選填)</span>
          </div>
          <div class="d-flex justify-content-between align-content-center">
            <Input
            :value="inputs.passenger.couponCode"
            :placeholder="'請輸入折扣碼'"
            :type="'text'"
            v-on:input="inputs.passenger.couponCode = $event"
            style="width: 70%"
          />
           <button 
            class="btn-use py-2 col-3" 
            @click.prevent="checkCouponCode(inputs.passenger.couponCode)"
          >
          使用
        </button>
          </div>
          <!-- 輸入錯誤跳的提示小字 -->
          <div class="d-flex align-content-start p-2 pt-1 notice-color">
            <span v-if="this.notices.notice_coupon_msg">{{this.notices.notice_coupon_msg}}</span>
          </div>
        </div>
        <!-- <div class="my-3">
          <div class="d-flex align-content-start text-input-describe p-2">
            訂票人姓名
          </div>
          <Input
            :value="inputs.passenger.name"
            :placeholder="'請輸入訂票人姓名'"
            :type="'text'"
            v-on:input="inputs.passenger.name = $event"
          />
          <div class="d-flex align-content-start p-2 pt-1 notice-color">
            <span v-if="notices.notice_name">{{notices.notice_name_msg}}</span>
          </div>
        </div> -->
        
        <!-- <div class="my-3">
          <div class="d-flex align-content-start text-input-describe p-2">
            生日
          </div>
          <InputBlank
            :value="inputs.passenger.birthday" 
            :placeholder="'請選擇訂票人生日'"
            :emit_event="'emit_showScroll'"
            @emit_showScroll="componentStatus.isShowingDateSelect=true"
          />
          <div class="d-flex align-content-start p-2 pt-1 notice-color">
            <span v-if="notices.notice_birthday">{{notices.notice_birthday_msg}}</span>
          </div>
        </div> -->
      </div>
      <div class="mx-4">
        <div class="d-flex my-4">
          <div class="mx-2">
            <input type="checkbox" v-model="inputs.check">
          </div>
          <div class="text-input-describe text-start">
            <span>我已明確了解 {{$t('store-name')}} 交易之</span>
            <span class="notice-color has-link-hover" v-on:click="newTab('service-terms')">服務條款</span>
            <span>及</span>
            <span class="notice-color has-link-hover" v-on:click="newTab('privacy-terms')">顧客個人隱私政策</span>
            <span>事項內容，並且同意遵守所有規定及提供所需之個人資料。</span>
          </div>
        </div>
        <div class="d-flex my-4">
          <div class="mx-2">
            <input type="checkbox" v-model="inputs.save">
          </div>
          <div class="text-input-describe text-start">
            <span>儲存此次填選資料為預設訂票個人資訊</span>
          </div>
        </div>
        <button 
          class="btn-next my-1 w-100" 
          :class="{ disabled: !inputs.check}"
          v-on:click="submit()"
        >
          確認
        </button>
      </div>

    <!-- <div v-if="componentStatus.isShowingDateSelect">
      <CustomDateSmoothPicker 
        :headerName="'選擇生日'"
        :maxDate="getToday()"
        :minDate="null"
        :target="'birthday'"
        @birthday="scrollResult"
        :currentYear="getCurrentYear()"
        :currentMonth="getCurrentMonth()"
        :currentDate="getCurrentDate()"
      />
    </div> -->
    
  </div>
</template>
<script>
import Input from "@/components/basic-components/input/custom-input.vue";
import { apiCheckCouponCode } from '@/apis/booking.js';
// import InputBlank from "@/components/basic-components/input/custom-input-blank.vue";
// import CustomDateSmoothPicker from "@/components/basic-components/custom-date-smooth-picker.vue";

export default {
  components: {
    Input,
    // InputBlank,
    // CustomDateSmoothPicker,
  },
  data() {
    return {
      componentStatus: {
        isShowingDateSelect: false,
      },
      inputs: {
        passenger: {
          // name: "",
          idcardNumber: "",
          phoneNumber: "",
          // birthday: "",
          couponCode:"",
          is_passport: false,
        },
        check: false,
        save: false,
      },
      notices: {
        notice_name: false,
        notice_name_msg: "",
        notice_id: false,
        notice_id_msg: "",
        notice_phone: false,
        notice_phone_msg: "",
        notice_birthday: false,
        notice_birthday_msg: "",
        notice_coupon_msg: "",
      },
    };
  },
  props:{
    "searchData":{
      type:Object
    }
  },
  methods: {
    init() {
      this.$store.commit("header/clearHeader");
      this.$store.commit("header/setHeader", {
        header: [
          { 
            title: "填寫訂票人資訊",
            type: "text",
          }
        ],
        type: "text"
      });
      this.takePreSaveInfo();
    },
    submit() {
      
      if ( this.validateInput() && this.inputs.check ) {

        if ( this.inputs.save ) {
          localStorage.setItem('saveOrderInfo', JSON.stringify(this.inputs.passenger));
        }
        this.$emit('info-data', this.inputs.passenger);
      }

    },
    validateInput() {
      
      // if ( this.inputs.passenger.name === "" ) {
      //   this.notices.notice_name = true;
      //   this.notices.notice_name_msg = "名稱不可為空"
      //   return false;
      // }
      // else {
      //   this.notices.notice_name = false;
      //   this.notices.notice_name_msg = ""
      // }
      let bool = true;
      if ( !this.inputs.passenger.is_passport && !this.validateIDNumber(this.inputs.passenger.idcardNumber) ) {
        this.notices.notice_id = true;
        this.notices.notice_id_msg = "身分證不符合規則"
        bool = false;
      }
      else if ( this.inputs.passenger.is_passport && !this.validatePassport(this.inputs.passenger.idcardNumber) ) {
        this.notices.notice_id = true;
        this.notices.notice_id_msg = "護照不符合規則"
        bool = false;
      }
      else {
        this.notices.notice_id = false;
        this.notices.notice_id_msg = ""

      }

      if ( !this.validatePhoneNumber(this.inputs.passenger.phoneNumber) ) {
        this.notices.notice_phone = true;
        this.notices.notice_phone_msg = "手機不符合規則"
        bool = false
      }
      else {
        this.notices.notice_phone = false;
        this.notices.notice_phone_msg = ""
      }

      // if ( this.inputs.passenger.birthday === "" ) {
      //   this.notices.notice_birthday = true;
      //   this.notices.notice_birthday_msg = "生日不可為空"
      //   return false;
      // }
      // else {
      //   this.notices.notice_birthday = false;
      //   this.notices.notice_birthday_msg = ""
      // }
      return bool ;

    },
    clickBack() {
      this.$emit('back', 'work');
    },
    takePreSaveInfo() {
      let data = JSON.parse(localStorage.getItem('saveOrderInfo'));
      if ( data !== null ) {
        this.inputs.passenger = data;
      }
    },
    scrollResult( data ) {
      this.inputs.passenger.birthday = data;
      this.componentStatus.isShowingDateSelect = false;
    },
    // getCurrentYear() {
    //   return parseInt(new Date(this.inputs.passenger.birthday).getFullYear());
    // },
    // getCurrentMonth() {
    //   return parseInt(new Date(this.inputs.passenger.birthday).getMonth()+1);
    // },
    // getCurrentDate() {
    //   return parseInt(new Date(this.inputs.passenger.birthday).getDate());
    // },
    newTab( routeName ) {
      console.log(routeName)
      let routeData = this.$router.resolve({name: routeName});
      window.open(routeData.href, '_blank');
    },
    async checkCouponCode( code ){
      console.log(`this.searchData`,this.searchData)
        let data = {
          adult:this.searchData.ticketFCount,
          children:this.searchData.ticketHCount,
          coupon_code:code
        }  
        let res = await apiCheckCouponCode(data)
        if(res.status === 200){
          this.notices.notice_coupon_msg = "折扣碼使用成功"
        }else{
          this.notices.notice_coupon_msg = res.message + "，請重新輸入或洽詢客服人員。"
        }
    }
  },
  mounted() {
    this.init();
  },
};
</script>
<style scope>
@import './style/fillInOrderInfo.css';



</style>
