
const state = () => ({
  lang: null  // 存放使用者選用的語系
});

const getters = {};

const actions = {};

const mutations = {
  setLang (state, value) {
    state.lang = value;
  }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}