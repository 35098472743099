<template>
  <div class="pb-1 mx-auto order">
    <div 
     class="order-header d-flex align-items-center justify-content-between"
     :class="{'paid': orderData.paid }"
    >
      <div class="d-flex justify-content-between">
        <div class="btn-fare h-25p text-start mx-4"
         :class="{'bg-trans':!orderData.paid, 'bg-mc':orderData.paid}"
        >
          來回票
        </div>
        <div class="text-black-50 text-end px-2">
          <span class="mx-1" v-if="adultTicketCount > 0">
            <span>全:</span>
            <span>{{ adultTicketRemain }}/{{ adultTicketCount }}</span>
          </span>

          <span class="mx-1" v-if="childrenTicketCount > 0">
            <span>半:</span>
            <span>{{ childrenTicketRemain }}/{{ childrenTicketCount }}</span>
          </span>
        </div>
        
      </div>
      <div 
       v-if="orderData.paid&&isOrderRefundable()"
       v-on:click="clickRefund()"
       class="mx-4 notice-color has-link-hover d-flex align-items-center">
        <span class="mx-1">退票</span> 
        <img 
         src="@/assets/common/icons/icon_refund.svg" alt="">
      </div>
    </div>
    <div class="panel-bot-ship-list mx-4">
      <div class="my-4">
        <div class="d-flex">
          <div class="col-8 px-2 text-start">
            <span class="text-black-50">訂單編號</span>
            <span 
              class="text-dark fw-bold mx-2 text-end"
              :id="'order-' + orderNumber"
            >{{
              orderNumber
            }}</span>
          </div>
          <div class="col-4 text-end">
            <CopyBtn :input="orderNumber"/>
          </div>
        </div>
        <div>
          <hr class="px-2" />
        </div>
        <div class="d-flex">
          <div class="col-4 px-2 text-start word-wrap">
            <span class="text-black-50">去程日期</span>
          </div>
          <div class="col-8 px-2 text-end">
            <span class="text-dark fw-bold mx-2 text-end word-wrap">
              {{ orderData.schedule.DL.date }}
              {{ getWeekday(orderData.schedule.DL.date) }}
              {{ orderData.schedule.DL.time }}
            </span>
          </div>
        </div>
        <div class="d-flex route-info align-items-center px-2">
          <span class="from fw-bold">東港</span>
          <span class="text-dark fs-2em mx-4">
            &rarr;
          </span>
          <span class="to fw-bold">小琉球</span>
        </div>
        <div>
          <hr class="px-2" />
        </div>
        <div class="d-flex">
          <div class="col-4 px-2 text-start word-wrap">
            <span class="text-black-50">去程日期</span>
          </div>
          <div class="col-8 px-2 text-end">
            <span class="text-dark fw-bold mx-2 text-end word-wrap">
              {{ orderData.schedule.LD.date }}
              {{ getWeekday(orderData.schedule.LD.date) }}
              {{ orderData.schedule.LD.time }}
            </span>
          </div>
        </div>
        <div class="d-flex route-info align-items-center px-2">
          <span class="from fw-bold">小琉球</span>
          <span class="text-dark fs-2em mx-4">
            &rarr;
          </span>
          <span class="to fw-bold">東港</span>
        </div>
        <div>
          <hr class="px-2" />
        </div>
        <div class="d-flex justify-content-between">
          <div class="px-2 text-start word-wrap">
            <div class="text-dark">總票價 新台幣</div>
            <div class="notice-color fw-bold text-end">NT${{numberWithCommas(totalPrice)}}</div>
          </div>
          <button 
            v-on:click="statusButtonClick(getStatus)"
            class="btn-status"
            :class="{ 
              'not-paid': getStatus==='未付款',
              'paid': getStatus==='可取票',
              'no-remain': getStatus==='已取票',
              'refunded': getStatus==='已退票',
              }"
          >{{ getStatus }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CopyBtn from '@/components/basic-components/btn-copy/btn-copy.vue';
import { mapGetters } from 'vuex';
import { apiPostRefundTickets } from "@/apis/refund.js";

export default {
  props: {
    orderNumber: String,
    orderData: Object,
    info: String,
  },
  components: {
    CopyBtn
  },
  data() {
    return {
      refundTarget: false,
    }
  },
  watch: {
    'modalConfirmState': async function() {
      if ( this.$store.getters["modal/getModalConfirm"]
        && this.$store.getters["modal/getModalTarget"]==='order-refund'
        && this.refundTarget ) {
        this.refundTarget = false ;
        console.log('refund info:', this.orderNumber, this.info);
        await this.refundOrder();
      }
    },
    'modalActiveState': function() {
      if ( this.$store.getters["modal/getModalTarget"]!=='order-refund' ) {
        this.refundTarget = false ;
      }
    },
  },
  computed: {
    ...mapGetters({
      modalConfirmState: 'modal/getModalConfirm',
      modalActiveState: 'modal/getModalActive',
    }),
    adultTicketCount() {
      if ( this.orderData.fares.length >= 1 && this.orderData.fares[0].type === "adult" ) {
        return this.orderData.fares[0].count;
      } else if ( this.orderData.fares.length === 2 && this.orderData.fares[1].type === "adult" ) {
        return this.orderData.fares[1].count;
      } else {
        return 0;
      }
    },
    childrenTicketCount() {
      if ( this.orderData.fares.length >= 1 && this.orderData.fares[0].type === "children" ) {
        return this.orderData.fares[0].count;
      } else if ( this.orderData.fares.length === 2 && this.orderData.fares[1].type === "children" ) {
        return this.orderData.fares[1].count;
      } else {
        return 0;
      }
    },
    adultTicketRemain() {
      if ( this.orderData.fares.length >= 1 && this.orderData.fares[0].type === "adult" ) {
        return this.orderData.fares[0].remain;
      } else if ( this.orderData.fares.length === 2 && this.orderData.fares[1].type === "adult" ) {
        return this.orderData.fares[1].remain;
      } else {
        return 0;
      }
    },
    childrenTicketRemain() {
      if ( this.orderData.fares.length >= 1 && this.orderData.fares[0].type === "children" ) {
        return this.orderData.fares[0].remain;
      } else if ( this.orderData.fares.length === 2 && this.orderData.fares[1].type === "children" ) {
        return this.orderData.fares[1].remain;
      } else {
        return 0;
      }
    },
    totalPrice() {
      let totalPrice = 0 ;
      for ( let i = 0 ; i < this.orderData.fares.length ; i++ ) {
        totalPrice += this.orderData.fares[i].count * this.orderData.fares[i].price;
      }
      return totalPrice;
    },
    getStatus() {

      if ( this.orderData.paid === true && this.isOrderRefunded() ) {
        return "已退票";
      }
      else if ( this.orderData.paid === false ) {
        return "未付款";
      }
      else if ( this.orderData.paid === true ) {
        if ( this.isRemainTicket ) {
          return "可取票";
        }
        else {
          return "已取票";
        }
      }
      else {
        return "未取得票狀態"
      }
    },
    isRemainTicket() {
      for ( let i = 0 ; i < this.orderData.fares.length ; i++ ) {
        if ( this.orderData.fares[i].remain > 0 )
          return true ;
      }
      return false ;
    }
  },
  methods: {
    numberWithCommas(num) {
      if (this.isNotUndefined(num))
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      else return "";
    },
    isNotUndefined(val) {
      // console.log( val, typeof val === 'undefined' )
      if (typeof val === "undefined") {
        return false;
      } else {
        return true;
      }
    },
    statusButtonClick(status){
      if ( status === '可取票' ) {
        // 直接跳下載取票頁面 ;
        this.$router.push({ 
          path:"/d",
          query: {
            o_n: this.orderNumber,
            o_i: this.info
          }
        });
      }
      else if ( status === '未付款' ) {
        this.$router.push({ name: 'OrderDetail', params: { 
          order_number: this.orderNumber,
          info: this.info,
        }});
      }
    },
    clickRefund() {
      this.refundTarget = true;
      let refundedable = this.getRefundableTickets();
      this.$store.commit("modal/setModal", {
        active: true,
        status: "select",
        message: [
          {
            type: "text",
            content: "您確定要退票嗎？",
            fontWeight: "bold",
            color: "maincolor",
            mt: 3,
            mb: 0,
          },
          {
            type: "text",
            content: "退票共" + refundedable.out + "張去程，" + refundedable.in + "張回程",
            fontWeight: "normal",
            color: "maincolor",
            mt: 0,
            mb: 3,
          },
          {
            type: "text",
            content: '注意：包含"已取票且未使用"之船票，已過期及已使用之船票不予退票。',
            fontWeight: "normal",
            color: "notice",
            mt: 2,
            mb: 2,
          },
          {
            type: "text",
            content: "退票最後期限為船票出發當日，",
            fontWeight: "normal",
            color: "maincolor",
            mt: 1,
            mb: 0,
          },
          {
            type: "text",
            content: '並酌收"手續費10%"',
            fontWeight: "underline",
            color: "maincolor",
            mt: 0,
            mb: 0,
          },
          {
            type: "text",
            content: "退費流程依據各發卡銀行作業時間不定，約需幾個工作天完成。",
            fontWeight: "normal",
            color: "maincolor",
            mt: 1,
            mb: 0,
          },
        ],
        target: "order-refund",
        options: [],
      });
    },
    isOrderRefunded() {
      let hasRefundedTicket = false ;
      for ( let i = 0 ; i < this.orderData.fares.length ; i++ ) {
        
        for ( let fare_index = 0 ; fare_index < this.orderData.fares[i].simple_ticket_infos.length ; fare_index++ ) {

          if ( this.orderData.fares[i].simple_ticket_infos[fare_index].out.status === 'refunded'
            || this.orderData.fares[i].simple_ticket_infos[fare_index].in.status === 'refunded' ) {
            hasRefundedTicket = true ;
          }

        }

      }
      for ( let i = 0 ; hasRefundedTicket && i < this.orderData.fares.length ; i++ ) {
        
        for ( let fare_index = 0 ; fare_index < this.orderData.fares[i].simple_ticket_infos.length ; fare_index++ ) {

          if ( this.orderData.fares[i].simple_ticket_infos[fare_index].out.status !== 'refunded'
            && this.orderData.fares[i].simple_ticket_infos[fare_index].out.status !== 'used' ) {
            hasRefundedTicket = false ;
          }
          if ( this.orderData.fares[i].simple_ticket_infos[fare_index].in.status !== 'refunded'
            && this.orderData.fares[i].simple_ticket_infos[fare_index].in.status !== 'used' ) {
            hasRefundedTicket = false ;
          }

        }

      }
      return hasRefundedTicket;
    },
    isOrderRefundable() {
      let boolRefundable = false;
      for ( let i = 0 ; i < this.orderData.fares.length ; i++ ) {
        
        for ( let fare_index = 0 ; fare_index < this.orderData.fares[i].simple_ticket_infos.length ; fare_index++ ) {

          if ( !this.orderData.fares[i].simple_ticket_infos[fare_index].out.expired
            && this.orderData.fares[i].simple_ticket_infos[fare_index].out.status !== 'used'
            && this.orderData.fares[i].simple_ticket_infos[fare_index].out.status !== 'refunded' ) {
            boolRefundable = true ;
          }
          
          if ( !this.orderData.fares[i].simple_ticket_infos[fare_index].in.expired
            && this.orderData.fares[i].simple_ticket_infos[fare_index].in.status !== 'used'
            && this.orderData.fares[i].simple_ticket_infos[fare_index].in.status !== 'refunded' ) {
            boolRefundable = true ;
          }

        }

      }
      return boolRefundable;
    },
    getRefundableTickets(){
      let tickets = [];
      let outNum = 0;
      let inNum = 0;

      for ( let i = 0 ; i < this.orderData.fares.length ; i++ ) {

        for ( let fare_index = 0 ; fare_index < this.orderData.fares[i].simple_ticket_infos.length ; fare_index++ ) {

          if ( !this.orderData.fares[i].simple_ticket_infos[fare_index].out.expired
            && this.orderData.fares[i].simple_ticket_infos[fare_index].out.status !== 'used'
            && this.orderData.fares[i].simple_ticket_infos[fare_index].out.status !== 'refunded' ) {
            tickets.push({ 
              ticket_number: this.orderData.fares[i].simple_ticket_infos[fare_index].out.number,
              auth: this.orderData.fares[i].simple_ticket_infos[fare_index].out.auth,
            }) ;
            outNum++;
          }
          
          if ( !this.orderData.fares[i].simple_ticket_infos[fare_index].in.expired
            && this.orderData.fares[i].simple_ticket_infos[fare_index].in.status !== 'used'
            && this.orderData.fares[i].simple_ticket_infos[fare_index].in.status !== 'refunded' ) {
            tickets.push({ 
              ticket_number: this.orderData.fares[i].simple_ticket_infos[fare_index].in.number,
              auth: this.orderData.fares[i].simple_ticket_infos[fare_index].in.auth,
            }) ;
            inNum++;
          }

        }

      }
      return {
        out: outNum,
        in: inNum,
        tickets: tickets,
      };
    },
    async refundOrder() {
      let data = this.getRefundableTickets().tickets ;

      // for ( let i = 0 ; i < this.orderData.fares.length ; i++ ) {
      //   for ( let fare_index = 0 ; fare_index < this.orderData.fares[i].simple_ticket_infos.length ; fare_index++ ) {
      //     data.push({
      //       ticket_number: this.orderData.fares[i].simple_ticket_infos[fare_index].out.number,
      //       auth: this.orderData.fares[i].simple_ticket_infos[fare_index].out.auth,
      //     });
      //     data.push({
      //       ticket_number: this.orderData.fares[i].simple_ticket_infos[fare_index].in.number,
      //       auth: this.orderData.fares[i].simple_ticket_infos[fare_index].in.auth,
      //     });
      //   }
      // }
      console.log(data);
      let refundResult = await apiPostRefundTickets({
        tickets: data,
      });

      console.log(refundResult);
      if ( refundResult.status === 200 ) {
        this.$store.commit("modal/setModal", {
          active: true,
          status: "success",
          message: [
            {
              type: "text",
              content: this.execRefundResult(refundResult.data),
              fontWeight: "bold",
              color: "maincolor",
              mt: 3,
              mb: 2,
            },
            {
              type: "icon",
              content: "success",
              fontWeight: "normal",
              color: "notice",
              mt: 2,
              mb: 3,
            },
          ],
        });

        let thisVue = this;
        setTimeout( function(){ 
          thisVue.$store.commit("modal/clearState");
          window.location.href = "/order" ;
        }, 3000 );
      }
      else {
        this.$store.commit("modal/setModal", {
          active: true,
          status: "fail",
          message: [
            {
              type: "text",
              content: refundResult.message,
              fontWeight: "bold",
              color: "notice",
              mt: 3,
              mb: 2,
            },
            {
              type: "icon",
              content: "fail",
              fontWeight: "normal",
              color: "notice",
              mt: 2,
              mb: 3,
            },
          ],
        });
      }

    },
    execRefundResult( refundResultArray ) {
      let message = "船票\n";
      for ( let i = 0 ; i < refundResultArray.length ; i++ ) {
        message += refundResultArray[i].ticket_number + "退票"
                + refundResultArray[i].message + "\n"
      }
      return message ;
    },
    
  },
  
};
</script>
<style scope>
@import './style/order.css';
</style>
