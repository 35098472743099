var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"top-bar d-flex align-items-center"},[_c('div',{staticClass:"w-100 d-flex align-items-center px-2"},[_c('div',{staticClass:"col-3 d-flex justify-content-center"},[_c('div',{staticClass:"d-flex button mx-1"},[_c('div',{staticClass:"arrow_lr left"}),_c('div',{staticClass:"mx-3 text-nowrap",on:{"click":function($event){return _vm.clickBack()}}},[_vm._v("返回")])])])])]),_c('div',{staticClass:"pay"},[_c('div',{staticClass:"my-3"},[_c('div',{staticClass:"mx-4 text-start my-4"},[_c('div',[_vm._m(0),_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',{staticClass:"text-dark fw-bold"},[_vm._v(_vm._s(_vm.payData.order_number))]),_c('CopyBtn',{attrs:{"input":_vm.payData.order_number}})],1)]),_c('hr'),_c('div',[_vm._m(1),_c('div',{staticClass:"text-dark fw-bold"},[_vm._v(" "+_vm._s(_vm.getToday())+" "+_vm._s(_vm.getWeekday(_vm.getToday()))+" "+_vm._s(_vm.getNowTime())+" ")])]),_c('hr'),_c('div',{staticClass:"pb-2"},[_vm._m(2),_c('div',{staticClass:"text-dark fw-bold"},[_vm._v(" 新台幣"),_c('span',{staticClass:"notice-color"},[_vm._v("NT$"+_vm._s(_vm.numberWithCommas(_vm.payData.price)))]),_vm._v("元 ")])])]),_vm._m(3),_c('div',{staticClass:"mx-4 text-start my-4"},[_c('div',{staticClass:"my-4"},[_vm._m(4),_c('div',{staticClass:"text-dark fw-bold"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputs.name),expression:"inputs.name"}],class:{
                  notice:
                    _vm.pageStatus.notices[0] !== undefined &&
                    _vm.pageStatus.notices[0].desc !== 'success',
                },attrs:{"type":"text","placeholder":_vm.pageStatus.notices[0] !== undefined &&
                  _vm.pageStatus.notices[0].desc !== 'success'
                    ? _vm.pageStatus.notices[0].desc === 'null'
                      ? '持卡人姓名為必填'
                      : '持卡人姓名不符合規則'
                    : '請輸入持卡人姓名'},domProps:{"value":(_vm.inputs.name)},on:{"blur":function($event){return _vm.validateInput(false)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.inputs, "name", $event.target.value)}}})])]),_c('div',{staticClass:"my-4"},[_vm._m(5),_c('div',{staticClass:"text-dark fw-bold"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputs.phone),expression:"inputs.phone"}],class:{
                  notice:
                    _vm.pageStatus.notices[1] !== undefined &&
                    _vm.pageStatus.notices[1].desc !== 'success',
                },attrs:{"type":"text","placeholder":_vm.pageStatus.notices[1] !== undefined &&
                  _vm.pageStatus.notices[1].desc !== 'success'
                    ? _vm.pageStatus.notices[1].desc === 'null'
                      ? '手機號碼為必填'
                      : '手機號碼不符合規則'
                    : '請輸入手機號碼'},domProps:{"value":(_vm.inputs.phone)},on:{"blur":function($event){return _vm.validateInput(false)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.inputs, "phone", $event.target.value)}}})])]),_c('div',{staticClass:"my-4"},[_vm._m(6),_c('div',{ref:"number",staticClass:"form-control",staticStyle:{"height":"40px","border-radius":"25px"}})]),_c('div',{staticClass:"my-4"},[_vm._m(7),_c('div',{ref:"expirationDate",staticClass:"form-control",staticStyle:{"height":"40px","border-radius":"25px"}})]),_c('div',{staticClass:"my-4"},[_vm._m(8),_c('div',{ref:"ccv",staticClass:"form-control",staticStyle:{"height":"40px","border-radius":"25px"}})])]),_c('div',[_c('button',{staticClass:"btn-pay next w-75",class:{'disabled':!_vm.pageStatus.tappayStatus.canGetPrime||!_vm.pageStatus.customerInfoInputValidate},on:{"click":function($event){return _vm.payConfirm()}}},[_vm._v(" 確認付款 ")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-between my-2"},[_c('span',{staticClass:"col-4 text-input-describe"},[_vm._v("訂票編號")]),_c('span',{staticClass:"d-flex justify-content-end"},[_c('div',{staticClass:"btn-fare bg-mc h-25p"},[_vm._v(" 來回票 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex my-2"},[_c('span',{staticClass:"col-4 text-input-describe"},[_vm._v("交易日期")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex my-2"},[_c('span',{staticClass:"col-4 text-input-describe"},[_vm._v("交易金額")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-bar d-flex align-items-center"},[_c('div',{staticClass:"w-100 d-flex align-items-center justify-content-center px-2"},[_vm._v(" 信用卡資料 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex row my-1"},[_c('span',{staticClass:"text-input-describe"},[_vm._v("持卡人姓名"),_c('span',{staticClass:"notice-color"},[_vm._v("*")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex row my-1"},[_c('span',{staticClass:"text-input-describe"},[_vm._v("持卡人手機號碼"),_c('span',{staticClass:"notice-color"},[_vm._v("*")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex row my-1"},[_c('label',{staticClass:"text-input-describe",attrs:{"for":"number"}},[_vm._v("信用卡卡號"),_c('span',{staticClass:"notice-color"},[_vm._v("*")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex row my-1"},[_c('label',{staticClass:"text-input-describe",attrs:{"for":"cardExpirationDate"}},[_vm._v("有效期限"),_c('span',{staticClass:"notice-color"},[_vm._v("*")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex row my-1"},[_c('label',{staticClass:"text-input-describe",attrs:{"for":"cardCcv"}},[_vm._v("檢查碼"),_c('span',{staticClass:"notice-color"},[_vm._v("*")]),_vm._v("(背面後三碼) ")])])
}]

export { render, staticRenderFns }