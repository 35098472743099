<template>
  <div>
    <div class="top-bar d-flex align-items-center">
      <div class="w-100 d-flex align-items-center px-2">
        <div class="col-3 d-flex justify-content-center">
          <div class="d-flex button mx-1">
            <div class="arrow_lr left"></div>
            <div class="mx-3 text-nowrap" v-on:click="clickBack()">返回</div>
          </div>
        </div>   
      </div>
    </div>
    <div class="pay">
        <div class="my-3">
          <div class="mx-4 text-start my-4">
            <div>
              <div class="d-flex justify-content-between my-2">
                <span class="col-4 text-input-describe">訂票編號</span>
                <span class="d-flex justify-content-end">
                  <div class="btn-fare bg-mc h-25p">
                    來回票
                  </div>
                </span>
              </div>
              <div class="d-flex justify-content-between">
                <span class="text-dark fw-bold">{{ payData.order_number }}</span>
                <CopyBtn :input="payData.order_number"/>
              </div>
            </div>
            <hr />
            <div>
              <div class="d-flex my-2">
                <span class="col-4 text-input-describe">交易日期</span>
              </div>
              <div class="text-dark fw-bold">
                {{ getToday() }} {{ getWeekday(getToday()) }} {{ getNowTime() }}
              </div>
            </div>
            <hr />
            <div class="pb-2">
              <div class="d-flex my-2">
                <span class="col-4 text-input-describe">交易金額</span>
              </div>
              <div class="text-dark fw-bold">
                新台幣<span class="notice-color"
                  >NT${{ numberWithCommas(payData.price) }}</span
                >元
              </div>
            </div>
          </div>

          <div class="top-bar d-flex align-items-center">
            <div class="w-100 d-flex align-items-center justify-content-center px-2">
              信用卡資料 
            </div>
          </div>

          <div class="mx-4 text-start my-4">
            <div class="my-4">
              <div class="d-flex row my-1">
                <span class="text-input-describe"
                  >持卡人姓名<span class="notice-color">*</span></span
                >
              </div>
              <div class="text-dark fw-bold">
                <input
                  type="text"
                  :class="{
                    notice:
                      pageStatus.notices[0] !== undefined &&
                      pageStatus.notices[0].desc !== 'success',
                  }"
                  :placeholder="
                    pageStatus.notices[0] !== undefined &&
                    pageStatus.notices[0].desc !== 'success'
                      ? pageStatus.notices[0].desc === 'null'
                        ? '持卡人姓名為必填'
                        : '持卡人姓名不符合規則'
                      : '請輸入持卡人姓名'
                  "
                  v-model="inputs.name"
                  v-on:blur="validateInput(false)"
                />
              </div>
            </div>
            <div class="my-4">
              <div class="d-flex row my-1">
                <span class="text-input-describe"
                  >持卡人手機號碼<span class="notice-color">*</span></span
                >
              </div>
              <div class="text-dark fw-bold">
                <input
                  type="text"
                  :class="{
                    notice:
                      pageStatus.notices[1] !== undefined &&
                      pageStatus.notices[1].desc !== 'success',
                  }"
                  :placeholder="
                    pageStatus.notices[1] !== undefined &&
                    pageStatus.notices[1].desc !== 'success'
                      ? pageStatus.notices[1].desc === 'null'
                        ? '手機號碼為必填'
                        : '手機號碼不符合規則'
                      : '請輸入手機號碼'
                  "
                  v-model="inputs.phone"
                  v-on:blur="validateInput(false)"
                />
              </div>
            </div>
            <!-- <div class="my-4">
              <div class="d-flex row my-1">
                <span class="text-input-describe"
                  >持卡人Email<span class="notice-color">*</span></span
                >
              </div>
              <div class="text-dark fw-bold">
                <input
                  type="text"
                  :class="{
                    notice:
                      pageStatus.notices[2] !== undefined &&
                      pageStatus.notices[2].desc !== 'success',
                  }"
                  :placeholder="
                    pageStatus.notices[2] !== undefined &&
                    pageStatus.notices[2].desc !== 'success'
                      ? pageStatus.notices[2].desc === 'null'
                        ? 'Email為必填'
                        : 'Email不符合規則'
                      : '請輸入Email'
                  "
                  v-model="inputs.email"
                  v-on:blur="validateInput(false)"
                />
              </div>
            </div> -->

            <div class="my-4">
              <div class="d-flex row my-1">
                <label 
                  for="number" 
                  class="text-input-describe"
                  >信用卡卡號<span class="notice-color">*</span>
                </label>
              </div>
              <div
                class="form-control"
                style="height:40px; border-radius:25px"
                ref="number"
              ></div>
            </div>
            <div class="my-4">
              <div class="d-flex row my-1">
                <label
                  for="cardExpirationDate"
                  class="text-input-describe"
                  >有效期限<span class="notice-color">*</span>
                </label>
              </div>
              <div
                class="form-control"
                style="height:40px; border-radius:25px"
                ref="expirationDate"
              ></div>
            </div>
            <div class="my-4">
              <div class="d-flex row my-1">
                <label 
                  for="cardCcv" 
                  class="text-input-describe"
                  >檢查碼<span class="notice-color">*</span>(背面後三碼)
                </label>
              </div>
              <div
                class="form-control"
                style="height:40px; border-radius:25px"
                ref="ccv"
              ></div>
            </div>
          </div>

          <div>
            <button class="btn-pay next w-75" 
             :class="{'disabled':!pageStatus.tappayStatus.canGetPrime||!pageStatus.customerInfoInputValidate}"
             v-on:click="payConfirm()">
              確認付款
            </button>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import getEnv from "@/utils/env.js";
import { apiPostPay } from "@/apis/pay.js";
import { apiGetSearchOrder } from "@/apis/download.js";
import CopyBtn from '@/components/basic-components/btn-copy/btn-copy.vue';
export default {
  props: {
    payData: Object,
  },
  components: {
    CopyBtn
  },
  data() {
    return {
      pageStatus: {
        notices: [],
        payDataTemp: {},
        bubbleMessage: "",
        tappayStatus: {},
        customerInfoInputValidate: false,
      },
      inputs: {
        name: "",
        phone: "",
        email: "",
      },
    };
  },
  methods: {
    setModalPayingLoading() {
      this.$store.commit("modal/setModal", {
        active: true,
        status: "waiting",
        message: [
          {
            type: "text",
            content: "交易處理中\n請稍候",
            fontWeight: "bold",
            color: "maincolor",
            mt: 3,
            mb: 3,
          },
          {
            type: "icon",
            content: "loading",
            fontWeight: "bold",
            color: "maincolor",
            mt: 3,
            mb: 3,
          },
        ],
      });
    },
    clickBack() {
      if ( this.$route.query.redirect_back ) {
        this.$router.push({ name: 'OrderDetail', params: { 
          order_number: this.payData.order_number,
          info: this.payData.info,
        }});
      }
      else {
        this.$emit('back');
      }
    },
    numberWithCommas(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    updateStatus(field) {
      switch (field) {
        case 0:
          //欄位已填好，並且沒有問題
          console.log("field is ok");
          break;
        case 1:
          //欄位還沒有填寫
          console.log("field is empty");
          break;
        case 2:
          //欄位有錯誤，此時在 CardView 裡面會用顯示 errorColor
          console.log("field has error");
          break;
        case 3:
          //使用者正在輸入中
          console.log("usertyping");
          break;
        default:
          console.log("error!");
      }
    },
    async payConfirm() {
      if ( !this.validateInput(true) || !this.pageStatus.tappayStatus.canGetPrime) {
        this.$store.commit("modal/setModal", {
          active: true,
          status: "fail",
          message: [
            {
              type: "text",
              content: "輸入資料格式有誤\n請確認填入資訊是否無誤",
              fontWeight: "bold",
              color: "notice",
              mt: 3,
              mb: 3,
            },
            {
              type: "icon",
              content: "fail",
              fontWeight: "normal",
              color: "",
              mt: 3,
              mb: 3,
            },
          ],
        });

      }
      else {
        this.setModalPayingLoading();

        this.onSubmit();
      }
    },
    async submitPayRequest(prime) {
      let data = {
        order_number: this.payData.order_number,
        info: this.payData.info,
        prime: prime,
        name: this.inputs.name,
        phone: this.inputs.phone,
        email: this.inputs.email,
        type: 0,
      };
      let result = await apiPostPay(data);
      if (result.status === 200 && result.data.success) {
        console.log("success", result);
        window.location.replace(result.data.redirect_url);
      } 
      else {
        this.$store.commit("modal/setModal", {
          active: true,
          status: "fail",
          message: [
            {
              type: "text",
              content: result.message,
              fontWeight: "bold",
              color: "notice",
              mt: 3,
              mb: 3,
            },
            {
              type: "icon",
              content: "fail",
              fontWeight: "normal",
              color: "",
              mt: 3,
              mb: 3,
            },
          ],
        });
        console.log("fail", result);
      }
    },
    // 觸發去取得狀態
    onSubmit() {
      const tappayStatus = TPDirect.card.getTappayFieldsStatus();
      if (tappayStatus.canGetPrime === false) {
        // can not get prime
        return;
      }

      // Get prime
      return TPDirect.card.getPrime((result) => {
        if (result.status !== 0) {
          // get prime error
          console.log(result.msg);
          return;
        }
        // console.log(result.card.prime);
        this.submitPayRequest(result.card.prime);
        // console.log(prime);
        // this.$emit('get-prime-and-cardowner-info', {
        //   prime: prime,
        //   cardOwner: {
        //     name: this.cardOwner.name,
        //     phone: this.cardOwner.phone,
        //     email: this.cardOwner.email,
        //   }

        // });
        // this.submitPrime(prime);
      });
    },
    async submitPrime(prime) {
      try {
        // 要把得到的Prime Token 送給後端,
        let payReslut = await this.apiPayByPrime(prime);
        if (payReslut.result.status === 0) {
          this.$notify({
            group: "paidSuccess",
            type: "success",
            text: "付款成功, （僅為展示頁面,不會進行出貨）",
          });

          this.createAnOrder();
          this.setStep(3);
        } else {
          this.$notify({
            group: "paidFail",
            type: "warn",
            text: "無法進行付款",
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    validateInput( submit_notice ) {
      this.pageStatus.notices = [];
      let hasError = false;

      if (this.inputs.name === "") {
        this.pageStatus.notices.push({
          title: "name",
          desc: "null",
        });
        hasError = true;
      } else {
        this.pageStatus.notices.push({
          title: "name",
          desc: "success",
        });
      }

      if (this.inputs.phone === "") {
        this.pageStatus.notices.push({
          title: "phone",
          desc: "null",
        });
        hasError = true;
      } else if (!this.validatePhoneNumber(this.inputs.phone)) {
        this.pageStatus.notices.push({
          title: "phone",
          desc: "non-legal",
        });
        if ( submit_notice ) 
          this.inputs.phone = "";
        hasError = true;
      } else {
        this.pageStatus.notices.push({
          title: "phone",
          desc: "success",
        });
      }

      // if (this.inputs.email === "") {
      //   this.pageStatus.notices.push({
      //     title: "email",
      //     desc: "null",
      //   });
      //   hasError = true;
      // } else if (!this.validateEmail(this.inputs.email)) {
      //   this.pageStatus.notices.push({
      //     title: "email",
      //     desc: "non-legal",
      //   });
      //   if ( submit_notice )
      //     this.inputs.email = "";
      //   hasError = true;
      // } else {
      //   this.pageStatus.notices.push({
      //     title: "email",
      //     desc: "success",
      //   });
      // }

      if ( !submit_notice ) {
        this.pageStatus.notices = [];
        this.pageStatus.customerInfoInputValidate = !hasError;
      }

      if (hasError) {
        return false;
      } else return true;
    },
    setupTapPay() {
      let APP_ID = getEnv("VUE_APP_TAP_PAY_APP_ID");
      let APP_KEY = getEnv("VUE_APP_TAP_PAY_APP_KEY");
      TPDirect.setupSDK(APP_ID, APP_KEY, getEnv("VUE_APP_TAP_PAY_TARGET_ENV"));

      const fields = {
        number: {
          element: this.$refs.number,
          placeholder: "請輸入卡號",
        },
        expirationDate: {
          element: this.$refs.expirationDate,
          placeholder: "MM/YY",
        },
        ccv: {
          element: this.$refs.ccv,
          placeholder: "請輸入檢查碼",
        },
      };

      TPDirect.card.setup({
        fields: fields,
        styles: {
          // Style all elements
          input: {
            color: "gray",
          },
          // Styling ccv field
          "input.ccv": {},
          // Styling expiration-date field
          "input.expiration-date": {},
          // Styling card-number field
          "input.card-number": {},
          // style focus state
          ":focus": {
            // 'color': 'black'
          },
          // style valid state
          ".valid": {
            color: "green",
          },
          // style invalid state
          ".invalid": {
            color: "red",
          },
          // Media queries
          // Note that these apply to the iframe, not the root window.
          "@media screen and (max-width: 400px)": {
            input: {
              color: "orange",
            },
            //   'input::placeholder': {
            //     color: '#ffffff'
            //   }
          },
        },
      });

      TPDirect.card.onUpdate((update) => {
        if (update.canGetPrime) {
          //全部欄位皆為正確 可以呼叫 getPrime
          this.disabledBtnPay = false;
        } else {
          this.disabledBtnPay = false;
        }

        this.updateStatus(update.status.number);
        this.updateStatus(update.status.expiry);
        this.updateStatus(update.status.ccv);
        this.pageStatus.tappayStatus = TPDirect.card.getTappayFieldsStatus();
      });
    },
    execPaidStatus() {
      if ( this.pageStatus.payDataTemp.paid ) {

        let thisVue = this;
        this.$store.commit("modal/setModal", {
          active: true,
          status: "success",
          message: [
            {
              type: "text",
              content: "交易成功",
              fontWeight: "bold",
              color: "maincolor",
              mt: 3,
              mb: 3,
            },
            {
              type: "icon",
              content: "success",
              fontWeight: "normal",
              color: "",
              mt: 3,
              mb: 3,
            },
          ],
        });
        
        setTimeout( function(){ 
          thisVue.$store.commit("modal/clearState");
          thisVue.$router.push('/order?status=paid');
        }, 3000 );
      }
      else if ( !this.pageStatus.payDataTemp.paid ) {

        this.$store.commit("modal/setModal", {
          active: true,
          status: "fail",
          message: [
            {
              type: "text",
              content: "交易失敗\n請確認信用卡資訊是否無誤",
              fontWeight: "bold",
              color: "notice",
              mt: 3,
              mb: 3,
            },
            {
              type: "icon",
              content: "fail",
              fontWeight: "normal",
              color: "",
              mt: 3,
              mb: 3,
            },
          ],
        });

      }
    },
    async sendSearchRequest() { // AgainInPayPage
      let sendData = {
        order_number: this.payData.order_number,
        info: this.payData.info,
      };
      let result = await apiGetSearchOrder(sendData);
      if ( result.status === 200 ) {
        // update paid staus ;
        return result.data.paid;
      }
      else {
        console.log('fail', result);
      }
      return false;
    },
    copyToClipBoard(number) {
      navigator.clipboard.writeText(number)
      .then(
        () => {
            console.log("Text copied to clipboard...")
        }
      ).catch(
        err => {
            console.log('Something went wrong', err);
      });
    },
  },
  async mounted() {
    
    this.$store.commit("header/clearHeader");
    this.$store.commit("header/setHeader", {
      header: [
        { 
          title: "訂單付款",
          type: "text",
        }
      ],
      type: "text"
    });

    this.setupTapPay();
    this.pageStatus.payDataTemp = this.payData; // 盡量不要動prop下來的資料, 故額外開一個temp來做存放判斷 ;
    if ( this.payData.redirect_back && !this.pageStatus.payDataTemp.paid ) {
      this.setModalPayingLoading();
      let thisVue = this ;
      setTimeout( async function(){
        thisVue.pageStatus.payDataTemp.paid = await thisVue.sendSearchRequest();
        thisVue.execPaidStatus();
      }, 3000);
    }
    else if ( this.pageStatus.payDataTemp.paid ) {
      this.execPaidStatus();
    }
  },
};
</script>
<style scope>
@import './style/orderPay.css';
</style>
