<template>
  <div class="nav-button col-2 my-2 mt-3 px-0" @click.stop="doEmit()">
    <router-link class="font-size-12" v-bind:to="_button.route"
     :disabled="_button.block" :event="!_button.block ? 'click' : ''"
    >
      <div>
        <div>
          <div
          class="icon-height mask-image-style"
          :class="{'active': _button.active}"
          :style="maskImageStyleObject(_button.imgurl)">
          </div>

        </div>
        <div
        class="nav-button-name"
        :class="{'active-url':_button.active}">{{ _button.name }}</div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'bottom-nav-button',
  props: {
    _button: {
      name: String,
      route: String,
    },
    emitAction: String,
  },
  methods: {
    doEmit() {
      if ( this.emitAction !== null ) {
        this.$emit(this.emitAction);
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import './style/bottom-nav-button.css';
</style>
