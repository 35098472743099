export default {
    methods: {
        ChangeLanguage( _locale ) {
            // console.log( 'change lang to', _locale );
            this.$store.commit('lang/setLang', _locale);
            this.$i18n.locale = _locale;
            localStorage.setItem('footmark-lang', _locale);
            window.location.reload(); // 讓別的位置重新讀入i18n;
        },
    }

}