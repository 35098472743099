<template>
    <select 
     :name="name"
     class="custom-select form-select"
     v-model="value"
     @change="$emit('select', $event.target.value)"
    >
        <option 
          v-for="(item, index) in options" :key="index"
          :value="item.id"
          :disabled="overLimit(item.id)"
        >
        {{ item.name }} 
        {{ ( isFare && item.type!==undefined ) ?
           ( item.type === "adult" ?
             "(成人)" : ( item.type === "children") ? "(3~12歲、65歲以上)":"" ) : "" }}
        </option>
    </select>
</template>
<script>
export default {
    props: {
        name: String,
        options: Array,
        count: Array,
        isFare: Boolean,
    },
    data() {
        return {
            value: "",
            
        }
    },
    computed: {
        
    },
    methods: {
      overLimit(id) {
        // get the fare is count = limit or not ;
        for ( let i = 0 ; i < this.count.length ; i++ ) {
            if ( parseInt(this.count[i].id) === parseInt(id) && this.count[i].count >= this.count[i].limit) {
                return true ;
            }
        }
        return false ;
      },
      defaultSelect() {
        if ( this.options.length === 1 ) {
          this.value = this.options[0].id;
          this.$emit('select', this.value);
        }
        else if ( this.options.length > 1 ) {
          let adultIndex = this.findAdultIndex();
          // console.log('adult in index-', adultIndex);
          if ( adultIndex !== -1 && !this.overLimit(this.options[adultIndex].id) ) {
            this.value = this.options[adultIndex].id;
            this.$emit('select', this.value);
            return ;
          }

          for ( let i = 0 ; i < this.options.length ; i++ ) {
            // console.log("l",i);
            if ( !this.overLimit(this.options[i].id) ) {
              this.value = this.options[i].id;
              this.$emit('select', this.value);
              break;
            }
          }
        }
      },
      findAdultIndex() {
        for ( let i = 0 ; i < this.options.length ; i++ ) {
          if ( this.options[i].type !== undefined && this.options[i].type === "adult" ) {
            return i ;
          }
        }
        return -1 ;
      },
      init() {
        this.defaultSelect() ;
      }
    },
    mounted() {
      this.init();
    }
    
}
</script>
<style scoped>
@import './style/input-select.css';
</style>