export default class Passenger {
    name = "";
    idcardNumber = "";
    phoneNumber = "";
    birthday = "";
    fare_id = "";
    constructor( _n, _i, _p, _b, _f ) {
        this.name = _n;
        this.idcardNumber = _i;
        this.phoneNumber = _p;
        this.birthday = _b;
        this.fare_id = _f;
    }
}