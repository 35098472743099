<template>
  <TermComponent :termsName="'service-term'"></TermComponent>
</template>
<script>
import TermComponent from "./components/terms.vue";
export default {
  components: {
    TermComponent,
  },
}
</script>
<style scope>
@import './style/term.css';
</style>
