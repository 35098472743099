<template>
  <div class="col-12 ticket-board mx-0">
    <ul class="nav justify-content-center">
      <li class="nav-item">
          <!-- v-on:click="navTargeting = 'outbound'" -->
        <a
          :class="{ active: $route.query.t === 'outbound' }"
          class="nav-link"
          aria-current="page"
          href="/my-tickets?t=outbound"
          >{{ $t("outbound") }}</a
        >
      </li>
      <li class="nav-item" v-if="inboundTickets.length!==0||$route.query.t === 'inbound'">
        <a
          :class="{ active: $route.query.t === 'inbound' }"
          v-on:click="navTargeting = 'inbound'"
          class="nav-link"
          aria-current="page"
          href="/my-tickets?t=inbound"
          >{{ $t("inbound") }}</a
        >
      </li>
      <li class="nav-item">
        <a
          :class="{ active: $route.query.t === 'expired' }"
          v-on:click="navTargeting = 'expired'"
          class="nav-link"
          aria-current="page"
          href="/my-tickets?t=expired"
          >{{ $t("expired") }}</a
        >
      </li>
    </ul>
    <div>
        <TicketSplide
        v-if="loadEnd&&targetList($route.query.t).length!==0"
        :tickets="targetList($route.query.t)"
        :status="$route.query.t"
        />
        <div 
         class="empty-message"
         v-if="targetList($route.query.t).length===0">
            {{ $t('empty-message') }}
        </div>

    </div>

  </div>
</template>
<script>
import { apiPostCheckTicketStatus } from "@/apis/myTickets.js";
import TicketSplide from './components/tickets-splide.vue';
import asyncLocalStorage from '@/utils/asyncLocalStorage.js';
export default {
  components: {
    TicketSplide
  },
  data() {
    return {
      outboundTickets: [],
      inboundTickets: [],
      expiredTickets: [],
      unknownStatusTickets: [],
      options: {
        rewind: true,
        perPage: 2,
        perMove: 1,
        focus: "center",
        lazyLoad: "nearby",
        width: "120vw",
        fixedWidth: "74%",
        gap: "22px",
        trimSpace: false,
        arrows: false,
      },
      loadEnd: false
    };
  },
  methods: {
    targetList( target ) {
        if ( target === 'outbound' )
            return this.outboundTickets;
        else if ( target === 'inbound' ) 
            return this.inboundTickets;
        else if ( target === 'expired' )
            return this.expiredTickets;
    },
    async getTicketStatus(tickets) {
        if ( tickets !== null ) {
            let data = [];
            for (let i = 0; i < tickets.length; i++) {
                data.push({
                order_number: tickets[i].data.order_number,
                ticket_number: tickets[i].data.number,
                auth: tickets[i].data.auth,
                });
            }
            let result = await apiPostCheckTicketStatus({
                query: data,
            });
            if (result.status === 200) 
                return result.data;
            else {
                console.log(result);
                return null;
            }
        }
        return null;
    },
    async init() {

      this.$store.commit("modal/setLoadingState");

      let ticketsLocal = JSON.parse(localStorage.getItem("tickets"));
      if ( ticketsLocal !== null && ticketsLocal.length > 0 ) {
        let statusList = await this.getTicketStatus(ticketsLocal);
        if (statusList === null) {

          console.log("持有票卷的狀態取得為空");
          this.$store.commit("modal/clearState");

        } else {
          await this.updateTicketsLocalStorage(statusList);
          let ticketsLocal = JSON.parse(localStorage.getItem("tickets"));
          this.setTicketList(ticketsLocal, statusList);
        }
      }
      else {

        console.log("持有票卷的狀態取得為空");
        this.$store.commit("modal/clearState");

      }
    },
    async updateTicketsLocalStorage(list) {
      let ticketsLocal = JSON.parse(localStorage.getItem("tickets"));

      for ( let i = 0 ; i < list.length ; i++ ) {
        for ( let ticIndex = 0 ; ticIndex < ticketsLocal.length ; ticIndex++ ) {
          if ( list[i].number === ticketsLocal[ticIndex].data.number ) {
            ticketsLocal[ticIndex].data = list[i];
          }
        }
      }

      await asyncLocalStorage.setItem("tickets", JSON.stringify(ticketsLocal)).then(function(){
        console.log('done, now localStorage tickets:', JSON.parse(localStorage.getItem('tickets')));
      });

    },
    setTicketList(ticketsLocal, statusList) {
        // console.log(ticketsLocal, statusList);
        for (let t_i = 0; t_i < ticketsLocal.length; t_i++) {
            let isSorted = false;
            for (let i = 0; i < statusList.length; i++) {
                if (statusList[i].number === ticketsLocal[t_i].data.number) {
                    if (
                    statusList[i].status === "picked_up" 
                    // && ticketsLocal[t_i].data.date >= this.getToday()
                    ) {
                    if (!ticketsLocal[t_i].data.is_inbound) {
                        this.outboundTickets.push({
                        data: ticketsLocal[t_i].data,
                        status: statusList[i].status,
                        });
                        isSorted = true;
                    } else {
                        this.inboundTickets.push({
                        data: ticketsLocal[t_i].data,
                        status: statusList[i].status,
                        });
                        isSorted = true;
                    }
                    } else {
                    if (
                        statusList[i].status === "used" ||
                        statusList[i].status === "refunded"
                        // || ticketsLocal[t_i].data.date < this.getToday()
                    ) {
                        this.expiredTickets.push({
                        data: ticketsLocal[t_i].data,
                        status: statusList[i].status,
                        });
                        isSorted = true;
                    }
                    }
                }
            }
            if (!isSorted) {
                this.unknownStatusTickets.push({
                    data: ticketsLocal[t_i].data,
                    status: "unknown",
                });
            }
            
        }
        
        this.$store.commit("modal/clearState");
        this.loadEnd = true;
    },
  },
  async mounted() {
    if ( this.$route.query.t === undefined )
        window.location.href = '?t=outbound';
    await this.init();
  },
};
</script>
<i18n>
{
    "zh-TW": {
        "outbound": "去程",
        "inbound": "回程",
        "expired": "失效",
        "title-ticket-type": "票種",
        "empty-message": "您尚未訂購任何票卷"
    },
    "en": {
        "outbound": "去程",
        "inbound": "回程",
        "expired": "失效",
        "title-ticket-type": "票種",
        "empty-message": "empty"
    }
}
</i18n>
<style scope>
@import "./style/tickets-board.css";
</style>
