const state = () => ({
    active: false,
    status: "",
    message: [],
    confirm: false,
    target: "",
    options: [],
});

/*
    state.message: 
        message: [
            {
                type: "text",
                content: "讀取中",
                fontWeight: "bold",
                color: "maincolor",
                mt: 3,
                mb: 3,
            },
            {
                type: "icon",
                content: "loading",
                fontWeight: "",
                color: "",
                mt: 3,
                mb: 3,
            },
            {
                type: "text-array",
                content: [
                    {
                        bold: false,
                        underline: false,
                        text: "請至",
                        color: "maincolor",
                    },
                    {
                        bold: true,
                        underline: true,
                        text: "「我的票卷」",
                        color: "maincolor",
                    },
                    {
                        bold: false,
                        underline: false,
                        text: "查看",
                        color: "maincolor",
                    }
                ]
            }
        ]
*/
  
const getters = {
    getModalActive: (state) => {
        return state.active;
    },
    getModalStatus: (state) => {
        return state.status;
    },
    getModalMessage: (state) => {
        return state.message;
    },
    getModalConfirm: (state) => {
        return state.confirm;
    },
    getModalTarget: (state) => {
        return state.target;
    },
    getModalOptions: (state) => {
        return state.options;
    }
};
  
const actions = {};
  
const mutations = {
    turnModalActive (state) {
        state.active = !state.active;
    },
    setModal(state, _state) {
        state.active = _state.active;
        state.status = _state.status;
        state.message = _state.message;
        state.target = (_state.target!==undefined)?_state.target:"";
        state.options = _state.options;
    },
    confirm(state) {
        state.confirm = true;
    },
    restoreConfirmState(state) {
        state.confirm = false;
        state.target = "";
    },
    setLoadingState(state) {
        state.active = true;
        state.status = "waiting";
        state.message = [
            {
                type: "text",
                content: "讀取中",
                fontWeight: "bold",
                color: "maincolor",
                mt: 3,
                mb: 3,
            },
            {
                type: "icon",
                content: "loading",
                fontWeight: "",
                color: "",
                mt: 3,
                mb: 3,
            }
        ];
        state.target = "";
        state.confirm = false;
        state.options = [];
    },
    clearState(state) {
        state.active = false;
        state.status = "";
        state.message = [];
        state.target = "";
        state.confirm = false;
        state.options = [];
    }
};
  
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}