<template>
  <div class="booking-page">

    <div class="top-bar d-flex align-items-center">
      <div class="mx-4 w-100 d-flex justify-content-between align-items-center">
        <div>
          <span v-if="bee_name === null">網路售票</span>
          <span v-else>{{ bee_name }}售票</span>
        </div>
        <div class="btn-fare h-30p bg-trans">
          來回票
        </div>

      </div>
    </div>
    <div class="mx-4 my-4">
      <div class="my-4">
        <div class="route-info text-start my-4">
          <div class="d-flex justify-content-between">
            <span class="text-input-describe">起點</span>
            <span class=""></span>
            <span class="text-input-describe text-end">終點</span>
          </div>
          <div class="d-flex justify-content-between">
            <span class="from fw-bold">東港</span>
            <div class="d-flex align-items-center justify-content-center text-dark fw-bold fs-2em">
              <img src="@/assets/common/icons/icon_double_arrow.svg" alt="">
            </div>
            <span class="to fw-bold text-end">小琉球</span>
          </div>
          <hr>
        </div>

        <div class="my-4">
          <div class="text-start my-1 d-flex justify-content-between align-items-center"
            v-on:click="setTargetAndShowScroll('departure-date')">
            <div class="col-11">
              <div class="text-input-describe">去程日期</div>
              <div class="d-flex justify-content-start col-11 text-dark fw-bolder fs-1p4em text-nowrap">
                <span v-if="componentData.departureDate === ''">請選擇去程日期</span>
                <span>{{ componentData.departureDate }}</span>
              </div>
            </div>
            <div class="col-1 d-flex justify-content-end align-items-center">
              <div class="arrow_lr right"></div>
            </div>
          </div>

          <hr />

          <div class="text-start my-1 d-flex justify-content-between align-items-center"
            v-on:click="setTargetAndShowScroll('return-date')">
            <div class="col-11">
              <div class="text-input-describe">回程日期</div>
              <div class="d-flex justify-content-start col-11 text-dark fw-bolder fs-1p4em text-nowrap">
                <span v-if="componentData.returnDate === ''">請選擇回程日期</span>
                <span>{{ componentData.returnDate }}</span>
              </div>
            </div>
            <div class="col-1 d-flex justify-content-end align-items-center">
              <div class="arrow_lr right"></div>
            </div>
          </div>

        </div>
        <hr />
        <div class="my-4">
          <div class="d-flex" v-on:click="setTargetAndShowScroll('tickets')">
            <div class="col-11 d-flex">
              <div class="col-5 d-flex align-items-start text-start text-input-describe">
                乘客人數
              </div>
              <div class="col-3">
                <div class="ticket-type-describe align-items-start pb-3">
                  全票
                </div>
                <div class="align-items-end text-dark fs-1p4em fw-bolder">
                  {{ componentData.ticketFCount }}
                </div>
              </div>
              <div class="col-4">
                <div class="ticket-type-describe align-items-start pb-3">
                  半票
                </div>
                <div class="align-items-end text-dark fs-1p4em fw-bolder">
                  {{ componentData.ticketHCount }}
                </div>
              </div>
            </div>

            <div class="col-1 d-flex justify-content-end align-items-center">
              <div class="arrow_lr right"></div>
            </div>
          </div>
          <hr />

          <div class="my-4 d-inline-flex">
            <span class="ticket-type-reminder">線上訂票不適用國旅卡<br>如需使用國旅卡請至現場直接購票</span>
          </div>

          <div class="my-4">
            <button class="btn-next fw-bold px-0" v-on:click="submit">
              下一步
            </button>
            <div class="d-flex align-content-start p-2 notice-color">
              <span v-if="componentStatus.notice">有未填寫項目</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-black" v-if="componentStatus.scrollShowing">
      <div class="cover">
        <div class="gray-background" v-on:click="confirmScroll">
        </div>
        <div class="smooth-picker-layout" v-if="componentStatus.selecting === 'date'">
          <div class="bg-white smooth-picker-header d-flex align-items-center justify-content-center">
            <div class="col-3">
            </div>
            <div class="col-6">
              選擇日期
            </div>
            <div class="col-3 text-end">
              <span class="smooth-picker-confirm mx-2" v-on:click="confirmScroll">確認</span>
            </div>
          </div>
          <div class="smooth-picker-body date">
            <smooth-picker ref="smoothPickerDate" :data="scrollingDate" :change="dateDataChange" />
          </div>

        </div>
        <div class="smooth-picker-layout" v-if="componentStatus.selecting === 'tickets'">
          <div class="bg-white smooth-picker-header d-flex align-items-center justify-content-center">
            <div class="col-3">
            </div>
            <div class="col-6">
              選擇票數
            </div>
            <div class="col-3 text-end">
              <span class="smooth-picker-confirm mx-2" v-on:click="confirmScroll">確認</span>
            </div>
          </div>
          <div class="bg-white w-100 smooth-picker-header d-flex align-items-center justify-content-center">
            <div class="w-50 text-center">
              全票
            </div>
            <div class="w-50 text-center">
              半票
            </div>
          </div>
          <div class="smooth-picker-body num">
            <smooth-picker ref="smoothPickerTicket" :data="scrollingTickets" :change="ticketsDataChange" />
          </div>
        </div>


      </div>
    </div>

  </div>
</template>
<script>
import { SmoothPicker } from 'vue-smooth-picker';
export default {
  components: {
    SmoothPicker
  },
  props: {
    bee_name: String
  },
  data() {
    return {
      componentStatus: {
        selecting: '', // 'date', 'tickets' ; 
        target: '', // 'departure', 'return', 'tickets' ;
        scrollShowing: false,
        notice: false
      },
      componentData: {
        departureDate: "",
        returnDate: "",
        fromStation: "東港",
        toStation: "小琉球",
        routeCode: "DL",
        ticketType: "來回",
        ticketFCount: 0,
        ticketHCount: 0,
      },
      navItems: [
        {
          text: "訂票",
          actionEmit: "",
          position: "left", // left, center or right,
          textType: "text", // text or action,
          bold: true,
          active: false,
        },

      ],
      scrollingDate: [
        {
          currentIndex: 0,
          flex: 3,
          list: [],
          textAlign: 'center',
          onClick: this.dateDataChange
        },
      ],
      scrollingTickets: [
        {
          currentIndex: 0,
          flex: 3,
          list: [],
          textAlign: 'center',
          className: 'row-group'
        },
        {
          currentIndex: 0,
          flex: 3,
          list: [],
          textAlign: 'center',
          className: 'row-group'
        },
      ],
    };
  },
  watch: {
    'componentData.departureDate': function () {
      if (this.componentData.departureDate > this.componentData.returnDate
        && (this.dateOffset(this.dateStringToYMDString(this.componentData.departureDate), this.getToday()) < 7)) {
        this.componentData.returnDate = this.dateStringAddDay(this.componentData.departureDate);
      }
      else {
        this.componentData.returnDate = this.componentData.departureDate;
      }
    },
    'componentData.returnDate': function () {
      if (this.componentData.departureDate > this.componentData.returnDate) {
        this.componentData.returnDate = this.componentData.departureDate;
      }
    }
  },
  methods: {
    submit() {
      // #TODO : 要補上資料檢查機制 ;
      if (this.componentData.departureDate !== ""
        && this.componentData.returnDate !== ""
        && (this.componentData.ticketFCount + this.componentData.ticketHCount) > 0) {
        this.componentStatus.notice = false;
        this.$emit('search-data', this.componentData);

      }
      else {
        this.componentStatus.notice = true;
      }
    },
    init() {
      this.$store.commit("header/setHeader", {
        header: [
          {
            title: "線上訂票",
            type: "text",
          }
        ],
        type: "text"
      });
      this.dateCreate(90, this.scrollingDate[0].list, this.getToday());
      this.componentData.departureDate = this.scrollingDate[0].list[0];
      this.componentData.returnDate = this.scrollingDate[0].list[1];

    },
    dateDataChange(gIndex, iIndex) {
      // console.info('list', gIndex, iIndex);
      this.scrollingDate[0].currentIndex = iIndex;

    },
    ticketsDataChange(gIndex, iIndex) {
      // console.info('list', gIndex, iIndex);
      this.scrollingTickets[gIndex].currentIndex = iIndex;
      if (gIndex === 0) {
        this.componentData.ticketFCount = this.scrollingTickets[gIndex].list[iIndex];
        let limitNum = 40 - this.scrollingTickets[0].currentIndex
        this.renderTicketList(limitNum,1)
        // console.log(`gc1`,this.scrollingTickets[1].currentIndex)
        let g1CurrentIndex = this.scrollingTickets[0].currentIndex + this.scrollingTickets[1].currentIndex > 40? this.scrollingTickets[1].currentIndex-1 : this.scrollingTickets[1].currentIndex
        this.$refs.smoothPickerTicket.setGroupData(1, Object.assign({}, this.scrollingTickets[1], { currentIndex:g1CurrentIndex}))
        // console.log(`g1CurrentIndex`,g1CurrentIndex)
      }
      else if (gIndex === 1) {
        this.componentData.ticketHCount = this.scrollingTickets[gIndex].list[iIndex];

      }
    },

    renderTicketList(num,index) {
      this.scrollingTickets[index].list = []
      for (let i = 0; i <= num; i++) {
        this.scrollingTickets[index].list.push(i)
        // console.log(`this.scrollingTickets`,this.scrollingTickets[index].list)
      }
    },
    setTargetAndShowScroll(targetName) {
      if (targetName === 'departure-date') {
        this.componentStatus.selecting = 'date';
        this.componentStatus.target = 'departure';
      }
      else if (targetName === 'return-date') {
        this.componentStatus.selecting = 'date';
        this.componentStatus.target = 'return';
      }
      else if (targetName === 'tickets') {
        this.componentStatus.selecting = 'tickets';
        this.componentStatus.target = 'tickets';
      }

      this.componentStatus.scrollShowing = true;
    },

    confirmScroll() {

      if (this.componentStatus.target === 'departure') {
        let index = this.scrollingDate[0].currentIndex;
        this.componentData.departureDate = this.scrollingDate[0].list[index];
      }
      else if (this.componentStatus.target === 'return') {
        let index = this.scrollingDate[0].currentIndex;
        this.componentData.returnDate = this.scrollingDate[0].list[index];
      }
      else if (this.componentStatus.target === 'tickets') {
        let indexF = this.scrollingTickets[0].currentIndex;
        this.componentData.ticketFCount = this.scrollingTickets[0].list[indexF];
        let indexH = this.scrollingTickets[1].currentIndex;
        this.componentData.ticketHCount = this.scrollingTickets[1].list[indexH];
      }
      this.componentStatus.scrollShowing = false;
      this.componentStatus.selecting = "";
      this.componentStatus.target = "";
    },
    dateCreate(num, array, start) {
      let date = new Date(start);
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      let d = date.getDate();
      array.push(this.generateDateString(y, m, d));
      for (let i = 0; i < num; i++) {
        d++;
        if (m === 2) {
          let numOfFebruary = new Date(y, m, 0).getDate();
          if (d > numOfFebruary) {
            d = 1;
            m++;
          }
        }
        else if (m == 1 || m == 3 || m == 5 || m == 7 || m == 8 || m == 10 || m == 12) {
          if (d > 31) {
            d = 1;
            m++;
          }
        }
        else if (m == 4 || m == 6 || m == 9 || m == 11) {
          if (d > 30) {
            d = 1;
            m++;
          }
        }
        if (m > 12) {
          m = 1;
          y++;
        }
        array.push(this.generateDateString(y, m, d));
      }
    },


  },
  mounted() {
    this.init();
    this.renderTicketList(40,0)
    this.renderTicketList(40,1)
  }
};
</script>
<style scope>
@import './style/bookingSearch.css';
</style>
