<template>
    <div id="nav" class="bg-white fixed-bottom p-0 bar mx-auto shadow-sm
                         bar-width d-flex justify-content-around row">
        <NavButtonComponent
          v-if="isEnable('booking')"
          v-bind:_button="this.$store.getters['button/getButtonInfoById'](1)"
          :emitAction="null"
        />
        <NavButtonComponent
          v-if="isEnable('order')"
          v-bind:_button="this.$store.getters['button/getButtonInfoById'](2)"
          :emitAction="null"
        />
        <NavButtonComponent
          v-if="isEnable('my-tickets')"
          v-bind:_button="this.$store.getters['button/getButtonInfoById'](0)"
          :emitAction="null"
        />
        <NavButtonComponent
          v-if="isEnable('download')"
          v-bind:_button="this.$store.getters['button/getButtonInfoById'](3)"
          :emitAction="null"
        />
        <NavButtonComponent
          v-if="isEnable('chatwoot')"
          v-bind:_button="this.$store.getters['button/getButtonInfoById'](4)"
          :emitAction="null"
        />
        <div >

        </div>
      </div>
</template>
<script>
import NavButtonComponent from './components/bottom-nav-button.vue'
import getEnv from "@/utils/env.js";
import stringCompare from '@/utils/stringCompare.js';

export default {
  components: {
    NavButtonComponent,
  },
  data() {
    return {
      enableList: [],
    }
  },
  methods: {
    isEnable( name ){
      return stringCompare( name, this.enableList, ',' )
    }
  },
  mounted() {
    this.enableList = getEnv('VUE_APP_APP_ENABLE_FUNCTION');
  }
}
</script>
<style scoped>
@import './style/bottom-nav-bar.css';
</style>
