<template>
  <div>
    <Header />
    <div v-if="status==='view-list'">
      <OrderList />
    </div>
    <div v-if="status==='view-orderDetail'">
      <OrderDetail 
       :order="order"
       @pay-order="fetchPayData"
       @pay-redirect="fetchPayData"
      />
    </div>
    <div v-if="status==='paying'">
      <OrderPay
       :payData="payData"
       @back="status='view-orderDetail'"
       
      />
    </div>
  </div>
</template>
<script>
import Header from '@/components/layout-components/header/header.vue'
import OrderList from '@/components/pages/orderPage/orderList/orderList.vue';
import OrderDetail from '@/components/pages/orderPage/orderDetail/orderDetail.vue';
import OrderPay from '@/components/pages/orderPage/orderPay/orderPay.vue';

export default {
  components:{
    Header,
    OrderList,
    OrderDetail,
    OrderPay,
  },
  data() {
    return {
      status: '', // 'view-list', 'view-orderDetail',
      order: {},
      payData: {},
    }
  },
  watch:{
    '$route': function(){
      if ( this.$route.params.order_number !== undefined && this.$route.params.info != undefined ) {
        this.changeViewOrderStatus();
      }
      else {
        this.status = 'view-list';
      }
      
    }
  },
  methods: {
    init() {
      this.$store.commit('button/changeButtonActive',{
        id: 2,
      });
      this.changeViewOrderStatus();
    },
    changeViewOrderStatus() {
      if ( this.$route.params.order_number !== undefined && this.$route.params.info != undefined ) {
        this.status = 'view-orderDetail';
      }
      else {
        this.status = 'view-list';
      }
    },
    fetchPayData( data ) {
      this.payData = data;
      this.status = 'paying';
    },
  },
  mounted(){
    this.init();
  },
}
</script>