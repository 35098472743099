<template>
  <splide
    :options="options"
    ref="splideref"
    @splide:moved="getQRCode()"
    @splide:mounted="getQRCode()"
  >
    <splide-slide
      v-for="(item, index) in tickets"
      :key="index"
    >
      <div 
       class="ticket"
       :class="{ 'expired': status==='expired' }"
      >
        <div class="upper">
          <button 
           v-if="status==='expired'"
           v-on:click="clickDelete(parseInt(index))"
           type="button" class="btn-close close" aria-label="Close">
          </button>
          <div class="d-flex px-4 pt-4 justify-content-between">
            <img
              class="logo"
              :src="envGetImagePath( 'header_logo_ticket.png')" 
            />
            <div class="text-end">
              <div class="title">{{ $t("title-ticket-type") }}</div>
              <div class="d-flex align-items-center" v-if="ticketMainType(item.data.number)!=='M'">
                <div class="fares type-font mx-2">{{getTicketFare(item.data.name)}}</div>
                <div class="type-shape-bg type-font">
                  <div class="type">{{getTicketType(item.data.name)}}</div>
                </div>
              </div>
              <div class="d-flex align-items-end" v-if="ticketMainType(item.data.number)==='M'">
                <div class="title">{{item.data.name}}</div>
              </div>
            </div>
          </div>
          <div class="d-flex px-4 py-3 justify-content-between">
            <span class="status-text-color status fw-bold">{{getTicketStatus(item.status)}}</span>
            <span class="title">{{ $t("title-ticket-expire-notice") }}</span>
          </div>
        </div>
        <div class="mid justify-content-between">
          <div class="mid-left"></div>
          <hr />
          <div class="mid-right"></div>
        </div>
        <div class="under">
          <div
            class="px-4 route-height"
          >
            <div 
             class="d-flex justify-content-between align-items-center status-text-color" 
             v-if="ticketMainType(item.data.number)!=='M'">
              <div class="station">
                <span class="fw-bold cn">{{item.data.from_station}}</span>
                <br />
                <span class="en">{{item.data.from_station_en}}</span>
              </div>
              <div class="arrow"></div>
              <div class="station">
                <span class="fw-bold cn">{{item.data.to_station}}</span>
                <br />
                <span class="en">{{item.data.to_station_en}}</span>
              </div>
            </div>
          </div>
          <hr class="my-2" />
          <div class="d-flex px-4 justify-content-start">
            <div 
             class="info"
             v-if="ticketMainType(item.data.number)!=='M'"
            >
              <span class="title">{{ $t("title-ferry") }}</span>
              <br />
              <span class="content">{{item.data.ferry_name!==undefined?item.data.ferry_name:"藍白一號"}}</span>
            </div>
            <div class="info">
              <span class="title">{{ $t("title-date") }}</span>
              <br />
              <span class="content">{{item.data.date}}</span>
            </div>
            <EditBtn
             v-if="ticketMainType(item.data.number)!=='M'"
             :name="'修改'" 
            />
          </div>
          <div class="d-flex px-4 justify-content-start">
            <div 
             class="info"
             v-if="ticketMainType(item.data.number)!=='M'"
            >
              <span class="title">{{ $t("title-time") }}</span>
              <br />
              <span class="content">{{item.data.time}}</span>
            </div>
            <div class="info">
              <span class="title">{{ $t("title-passenger-name") }}</span>
              <br />
              <span class="content">{{item.data.passenger_name}}</span>
            </div>
          </div>
          <div class="d-flex px-4 justify-content-start">
            <div class="info">
              <span class="title">{{ $t("title-passenger-id") }}</span>
              <br />
              <span class="content">{{getHideString(item.data.passenger_id_number)}}</span>
            </div>
            <div class="info">
              <span class="title">{{ $t("title-passenger-phone") }}</span>
              <br />
              <span class="content">{{getHideString(item.data.passenger_phone)}}</span>
            </div>
          </div>
          <div class="d-flex px-4 py-2 justify-content-center">
              <img :src="qrcode" class="qrcode" alt="">
          </div>
          <div class="d-flex px-4 justify-content-center">
              <span class="title ticket-number mx-2">{{ $t("title-order-number") }}</span>
              <span class="title ticket-number mx-2">{{item.data.number}}</span>
          </div>
          <hr class="my-2"/>
          <div class="d-flex align-items-center justify-content-center"
           v-if="status!=='expired'&&item.data.refundable"
          >
            <div 
             class="notice-color has-link-hover d-flex align-items-center"
             v-on:click="clickRefundTicket(parseInt(index))"
            >
              <span class="mx-1">退票</span> 
              <img 
                src="@/assets/common/icons/icon_refund.svg" alt="">
            </div>
          </div>
        </div>
      </div>
    </splide-slide>
  </splide>
</template>
<script>
import { apiPostGetTicketQRCode } from "@/apis/myTickets.js";
import { apiPostRefundTickets } from "@/apis/refund.js";
import { mapGetters } from 'vuex';
import EditBtn from '@/components/basic-components/btn-edit/btn-edit.vue';
export default {
    props: {
        tickets: Array,
        status: String,
    },
    components: {
      EditBtn
    },
    data() {
        return {
            options: {
                rewind: true,
                perPage: 2,
                perMove: 1,
                focus: "center",
                lazyLoad: "nearby",
                width: "120vw",
                fixedWidth: "74%",
                gap: "22px",
                trimSpace: false,
                arrows: false,
            },
            qrcode: "",
            deleteTargetIndex: 0,
            refundTargetIndex: 0,
            currentIndex: 0,
        };
    },
    computed: {
        ...mapGetters({
            modalConfirmState: 'modal/getModalConfirm',
        })
    },
    watch: {
        'modalConfirmState': async function() {
            if ( this.$store.getters["modal/getModalConfirm"] && this.$store.getters["modal/getModalTarget"]==='ticket-delete' ) {
                this.deleteTicket( this.tickets[this.deleteTargetIndex].data.number );
            }
            else if ( this.$store.getters["modal/getModalConfirm"] && this.$store.getters["modal/getModalTarget"]==='ticket-refund' ) {
                await this.refundTicket( this.tickets[this.refundTargetIndex].data );
            }
        },

    },
    methods: {
        clickDelete( index ) {
            console.log(index);
            this.deleteTargetIndex = index;
            this.$store.commit("modal/setModal", {
                active: true,
                status: "select",
                message: [
                  {
                      type: "text",
                      content: "確定刪除該失效票卷？",
                      fontWeight: "bold",
                      color: "maincolor",
                      mt: 3,
                      mb: 3,
                  }
                ],
                target: "ticket-delete",
                options: [],
            });
        },
        clickRefundTicket( index ) {
          console.log( index );
          this.refundTargetIndex = index;
          this.$store.commit("modal/setModal", {
            active: true,
            status: "select",
            message: [
              {
                type: "text",
                content: "您確定要退票嗎？",
                fontWeight: "bold",
                color: "maincolor",
                mt: 3,
                mb: 3,
              },
              {
                type: "text",
                content: 
                  (( this.isOutboundTicket( index ) ) ?
                  "去程票退票時將會同時退掉回程票。\n回程船票號碼:" 
                  + this.tickets[index].data.pair_ticket_number 
                  + ( this.getTicketByNumber(this.tickets[index].data.pair_ticket_number).status === "refunded" ? "(已退票)":"" )
                  : ""),
                fontWeight: "normal",
                color: "notice",
                mt: 2,
                mb: 2,
              },
              {
                type: "text",
                content: '退票最後期限為船票出發當日，',
                fontWeight: "normal",
                color: "maincolor",
                mt: 3,
                mb: 0,
              },
              {
                type: "text",
                content: '並酌收"手續費10%"',
                fontWeight: "underline",
                color: "maincolor",
                mt: 0,
                mb: 0,
              },
              {
                type: "text",
                content: '退費流程依據各發卡銀行作業時間不定，約需幾個工作天完成。',
                fontWeight: "normal",
                color: "maincolor",
                mt: 0,
                mb: 3,
              },
            ],
            target: "ticket-refund",
            options: [],
          });
        },
        isOutboundTicket( index ) {
          if ( this.tickets[index].data.is_round_trip && !this.tickets[index].data.is_inbound ) {
            return true;
          }
          else
            return false;
        },
        deleteTicket( ticketNumber ) {
            // console.log(ticketNumber);

            let ticketsLocal = JSON.parse(localStorage.getItem("tickets"));
            for ( let i = 0 ; i < ticketsLocal.length ; i++ ) {
                if ( ticketsLocal[i].data.number === ticketNumber ) {
                    ticketsLocal.splice(i, 1);
                }
            }
            localStorage.removeItem("tickets");
            localStorage.setItem("tickets",JSON.stringify(ticketsLocal));

            // console.log(ticketsLocal);
            this.$store.commit("modal/restoreConfirmState");
            this.$store.commit("modal/clearState");
            window.location.href = "/my-tickets?t=" + this.$route.query.t ;

        },
        async refundTicket( ticket ) {
          // console.log( ticket );

          this.$store.commit("modal/setLoadingState");

          let refundTickets = [];
          refundTickets.push({
            ticket_number: ticket.number,
            auth: ticket.auth
          }) ;
          if ( ticket.is_round_trip && !ticket.is_inbound ) {
            let pairTicket = this.getTicketByNumber(ticket.pair_ticket_number);
            refundTickets.push({
              ticket_number: pairTicket.number,
              auth: pairTicket.auth,
            }) ;
          }

          console.log(refundTickets);
          let refundResult = await apiPostRefundTickets({
            tickets: refundTickets
          });
          console.log(refundResult);

          if ( refundResult.status === 200 ) {
            this.$store.commit("modal/setModal", {
              active: true,
              status: "success",
              message: [
                {
                  type: "text",
                  content: this.execRefundResult(refundResult.data),
                  fontWeight: "bold",
                  color: "maincolor",
                  mt: 3,
                  mb: 1,
                },
                {
                  type: "icon",
                  content: "success",
                  fontWeight: "normal",
                  color: "",
                  mt: 1,
                  mb: 3,
                },

              ],
            });

            let thisVue = this;
            setTimeout( function(){ 

              thisVue.$store.commit("modal/clearState");

              window.location.href = "/my-tickets?t=" + thisVue.$route.query.t ;
            }, 3000 );

          }
          else {
            this.$store.commit("modal/setModal", {
              active: true,
              status: "fail",
              message: [
                {
                  type: "text",
                  content: refundResult.message,
                  fontWeight: "normal",
                  color: "notice",
                  mt: 3,
                  mb: 3,
                }
              ],
            });
          }

        },
        execRefundResult( refundResultArray ) {
          let message = "船票\n";
          for ( let i = 0 ; i < refundResultArray.length ; i++ ) {
            message += refundResultArray[i].ticket_number + "退票"
                     + ( refundResultArray[i].success ? "成功":"失敗" ) + "\n"
          }
          return message ;
        },
        getTicketByNumber( ticketNumber ) {

          let ticketsLocal = JSON.parse(localStorage.getItem("tickets"));

          for ( let i = 0 ; i < ticketsLocal.length ; i++ ) {
            if ( ticketNumber === ticketsLocal[i].data.number )
              return ticketsLocal[i].data ;
          }

          return null;
        },
        getTicketType( string ) {
            return string.substring( string.length-2, string.length-1 );
        },
        getTicketFare( string ) {
            return string.substring( 0, 2 ) ;
        },
        getTicketStatus( status ) {
            if ( status === 'picked_up' )
                return " " ;
            else if ( status === 'refunded' ) 
                return "已退票" ;
            else if ( status === 'used' )
                return "已驗票" ;
        },
        getHideString( string ) {
            return string.substring( 0, string.length-5) + "*****";
        },
        async getQRCode() {
            // console.log(this.$refs.splideref.index);
            this.$store.commit("modal/setLoadingState");

            this.qrcode = "";
            let data = {
                order_number: this.tickets[this.$refs.splideref.index].data.order_number,
                ticket_number: this.tickets[this.$refs.splideref.index].data.number,
                auth: this.tickets[this.$refs.splideref.index].data.auth,
            };
            let result = await apiPostGetTicketQRCode(data);
            if (result.status === 200) {
                // console.log(result);
                this.qrcode = result.data.qrcode;
            } else {
                console.log(result);
                this.qrcode = "";
            }
            this.$store.commit("modal/clearState");
            
        },
        ticketMainType( ticketNumber ) {
          return ticketNumber.substring( 2, 3 );
        }
    },
    mounted() {

    }

}
</script>
<i18n>
{
    "zh-TW": {
        "title-ticket-type": "票種",
        "title-ticket-expire-notice": "票卷當日有效",
        "title-ferry": "船班資訊",
        "title-date": "日期",
        "title-time": "航班時間",
        "title-passenger-name": "乘客資訊",
        "title-passenger-id": "身分證字號",
        "title-passenger-phone": "聯絡電話",
        "title-order-number": "訂單票號"
    },
    "en": {
        "title-ticket-type": "票種",
        "title-ticket-expire-notice": "票卷當日有效",
        "title-ferry": "船班資訊",
        "title-date": "日期",
        "title-time": "航班時間",
        "title-passenger-name": "乘客資訊",
        "title-passenger-id": "身分證字號",
        "title-passenger-phone": "聯絡電話",
        "title-order-number": "訂單票號"
    }
}
</i18n>