<template>
    <div class="term p-4">
      <div class="px-4">
        <div class="header-title py-4">
          {{ $t(termsName+'.main-title') }}
        </div>
        <div class="d-block py-4 text-start">
          {{ $t(termsName+'.main-desc') }}
        </div>
        <div v-if="$t(termsName+'.content-type') === 'title-list'">
            <div
            v-for="(item, index) in $t(termsName+'.contents')" 
            :key="index"
            >
                <div class="title">
                    {{ item['sub-title'] }}
                </div>
                <div class="py-4 text-start">
                    <div v-if="item['type']==='div'">
                        <div v-for="(subitem, subIndex) in item['sub-contents']" :key="subIndex">
                            {{ subitem }}
                        </div>
                    </div>
                    <ol v-if="item['type']==='ol'">
                        <li v-for="(subitem, subIndex) in item['sub-contents']" :key="subIndex">
                            {{ subitem }}
                        </li>
                    </ol>
                    <ul v-if="item['type']==='ul'">
                        <li v-for="(subitem, subIndex) in item['sub-contents']" :key="subIndex">
                            {{ subitem }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div v-if="$t(termsName+'.content-type') === 'ul-list'">
            <ul class="py-4 text-start">
                <li 
                v-for="(item, index) in $t(termsName+'.contents')" 
                :key="index"
                >
                    {{ item }}
                </li>
            </ul>
        </div>
      </div>
    </div>
</template>
<script>
  export default {
    props: {
        termsName: String,
    }
  }
</script>
<style scope>
  @import './../style/term.css';
</style>
  