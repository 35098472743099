<template>
    <div>
        
    </div>
</template>
<script>
import { apiPostCheckBeeUUID } from '@/apis/bee.js';
export default {
    methods: {
        async init() {    
            await this.beeIdSetLoaclStorage() ;
            this.$router.push( { path:"/booking" } );
        },
        async beeIdSetLoaclStorage() {

            if ( this.$route.params.uuid !== "" ) {
                let uuid = this.$route.params.uuid;
                let vaildRes = await apiPostCheckBeeUUID({
                    uuid: uuid
                });
                if ( vaildRes.status === 200 && vaildRes.data.is_valid ) {
                    // 驗證成功才將此uuid紀錄下來 ;
                    localStorage.removeItem("bee_uuid");
                    localStorage.setItem("bee_uuid", uuid);
                    localStorage.removeItem("bee_name");
                    localStorage.setItem("bee_name", vaildRes.data.name);
                }
                
            }

        }

    }, 
    mounted() {
        this.init();
    }
}
</script>