import getEnv from '@/utils/env.js';
export default {
    methods: {
        envGetImagePath( assetImageName ) {
            let reqPath = require( 
                '@/assets/site/' + 
                ( getEnv('VUE_APP_IMAGE_PATH')
                  !==undefined?getEnv('VUE_APP_IMAGE_PATH'):'leuco' ) + 
                '/images/' + 
                assetImageName 
            ) ;

            return reqPath !== null 
                 ? reqPath 
                 : require( 
                    '@/assets/site/leuco/images/' + 
                    assetImageName 
                   ) ;
        },
        envGetStylePropPath() {
            let reqPath = require(
                '@/assets/site/' +
                ( getEnv('VUE_APP_IMAGE_PATH')
                  !==undefined?getEnv('VUE_APP_IMAGE_PATH'):'leuco' ) + 
                '/config-json/style.json' 
            ) ;

            return reqPath !== null 
            ? reqPath
            : require(
                '@/assets/site/leuco/config-json/style.json' 
              ) ;
        }
    },    
}